import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-preregistro04',
  templateUrl: './preregistro04.component.html',
  styleUrls: []
})

export class Preregistro04Component {

  constructor (private router: Router, private _location: Location){ }

  title = "Confirma tu correo electrónico";

}
