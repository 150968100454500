import { Component } from '@angular/core';

@Component({
  selector: 'app-divulgacion',
  templateUrl: './divulgacion.component.html',
  styles: [
  ]
})
export class DivulgacionComponent {

  title = 'Divulgación'

}
