<app-user-status></app-user-status>

<div class="container d-flex justify-content-center align-items-center contenedor-94">
    <div class="d-flex flex-column justify-content-evenly align-items-center">
        
        <div class="col-10 d-flex justify-content-center align-items-top aparece-dos">
            <h3 class="text-center">Progreso</h3>
            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-top" data-bs-toggle="modal" data-bs-target="#modal">
                <i class="fa-solid fa-circle-info text-primary p-1"></i>
            </button>
            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                    <div class="container">
                        <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-10">
                                <div class="card">
                                    <div class="card-body p-5">
                                        <div class="cont-btn-close">
                                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                <i class="fa-solid fa-x"></i>
                                            </button>
                                        </div>
                                        <h4 class="card-title text-center text-primary">Progreso</h4>
                                        <p class="card-text text-center">Info sobre el progreso</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="progresoCirculoCajaUno my-5 aparece" style="--porcentaje: 101">
            <svg class="progresoCirculo">
                <circle r="65" cx="50%" cy="50%" pathLength="100" />
                <circle r="65" cx="50%" cy="50%" pathLength="100" />
            </svg>
            <span>100%</span>
        </div>

        <div class="col-10 d-flex flex-column justify-content-center align-items-center aparece-tres">
            <span class="progresoFel text-center my-2">¡FELICIDADES!</span>
            <h4 class="text-center mt-2 mb-5">Has concluido tu registro con éxito</h4>
            <h3 class="my-2">¿Qué sigue?</h3>
            <p class="text-center my-2">Revisaremos tu contrato, tu información y nos pondremos en contacto contigo</p>
            <p class="text-center my-2"></p>
        </div>

        <div class="col-11 mt-5 aparece-tres">
            <button class="btn btn-primary w-100" (click)="logout()">Continuar</button>
        </div>

    </div>
</div>  