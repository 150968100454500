import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProgresoUsuarioService } from '../../../services/progreso-usuario/progreso-usuario.service'; 

@Component({
  selector: 'app-contrato01',
  templateUrl: './contrato01.component.html',
  styleUrls: []
})
export class Contrato01Component implements OnInit {
  title = "Firma tu contrato";
  pdfUrl: string | null = null;
  archivoSeleccionado: File | null = null;
  errorMessage: string | null = null;
  successMessage: string | null = null;
  nombreCuenta: string | null = null;
  nombreCuentaParaEnvio: string | null = null;

  private MAX_SIZE: number = 2 * 1024 * 1024;

  constructor(
    private http: HttpClient,
    private router: Router,
    private progresoUsuarioService: ProgresoUsuarioService
    ) { }

    ngOnInit(): void {
      this.progresoUsuarioService.checkUserProgressAndNavigate();
      this.obtenerNombreCuenta();
      this.obtenerNombreCuentaParaEnvio();
    }

  obtenerNombreCuenta() {
    this.http.get<any[]>('https://login.arcsacapital.com/cuentas_get').subscribe({
      next: (data) => {
        if (data.length > 0 && data[0].hasOwnProperty('nombreCuenta')) {
          const nombreCuenta = data[0].nombreCuenta;
          console.log('nombreCuenta obtenido:', nombreCuenta);
          this.solicitarPDF(nombreCuenta);
        } else {
          console.error('Error: No se encontró el campo nombreCuenta o el array está vacío.');
        }
      },
      error: (error) => {
        console.error('Error al realizar la solicitud GET:', error);
      }
    });
  }

  obtenerNombreCuentaParaEnvio() {
    this.http.get<{nombreCuenta: string}[]>('https://login.arcsacapital.com/cuentas_get')
        .subscribe({
            next: (data) => {
                if (data.length > 0 && data[0].nombreCuenta) {
                    this.nombreCuentaParaEnvio = data[0].nombreCuenta;
                    console.log('Nombre de cuenta para envío obtenido:', this.nombreCuentaParaEnvio);
                } else {
                    console.error('Error: No se encontró el campo nombreCuenta o el array está vacío.');
                }
            },
            error: (error) => {
                console.error('Error al realizar la solicitud GET:', error);
            }
        });
  }

  solicitarPDF(nombreCuenta: string) {
    const encodedNombreCuenta = encodeURIComponent(nombreCuenta);
    const url = `https://login.arcsacapital.com/generaPdf?nombreCuenta=${encodedNombreCuenta}`;
  
    console.log('URL solicitada:', url);
  
    this.http.get(url, { responseType: 'blob' as 'blob' }).subscribe({
      next: (blob) => {
        this.pdfUrl = window.URL.createObjectURL(blob); 
        console.log('PDF disponible en:', this.pdfUrl);
      },
      error: (error) => {
        console.error('Error al solicitar el PDF:', error);
      }
    });
  }  

  onFileSelect(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      if (file.size > this.MAX_SIZE) {
        this.errorMessage = `El archivo supera el límite de 2MB, tamaño del archivo: ${(file.size / 1024 / 1024).toFixed(2)} MB`;
        this.archivoSeleccionado = null;
      } else {
        this.archivoSeleccionado = file;
        this.errorMessage = null;
        this.successMessage = null; 
      }
    } else {
      this.errorMessage = 'No se ha seleccionado ningún archivo.';
    }
  }

  enviarContrato() {
    if (!this.archivoSeleccionado) {
      console.log('Por favor, seleccione un archivo antes de enviar.');
      return;
    }

    if (!this.nombreCuentaParaEnvio) {
      console.log('El nombre de la cuenta para envío no está disponible. Por favor, intente recargar la información.');
      return; // Abortar la operación si nombreCuenta no está disponible
    }

    const formData = new FormData();
    formData.append('contrato', this.archivoSeleccionado);
    formData.append('nombreCuenta', this.nombreCuentaParaEnvio); // Añadiendo nombreCuenta al FormData

    console.log('FormData Contents:');
    console.log('contrato:', formData.get('contrato'));
    console.log('nombreCuenta:', formData.get('nombreCuenta'));

    this.http.post('https://login.arcsacapital.com/contratos', formData).subscribe({
      next: response => {
        this.successMessage = 'Archivo subido con éxito. Redirigiendo...';
        setTimeout(() => {
          this.router.navigate(['/contrato02']);
        }, 3000);
      },
      error: error => {
        console.error('Error durante el envío del archivo:', error);
        console.log('Error desde el backend:', error.error ? error.error.message : 'No additional error info');
      }
    });
  }
}