<nav *ngIf="isLoggedIn" class="navbar bg-white fixed-top shadow">
  <div class="w-100" style="position: relative;">
    <div class="container-fluid contenedor-top-logged">
      <div class="w-100 d-flex flex-row justify-content-between align-items-center">
        <a *ngIf="showBackButton" (click)="regresar()" style="cursor: pointer !important;">
          <i class="fa-solid fa-arrow-left fs-1 text-secondary" style="overflow: hidden; cursor: pointer !important;"></i>
        </a>
        <p class="navbar-brand pt-2 ms-5">Bienvenido: <span class="fs-3 fw-medium">{{ nombreUsuario }}</span></p>
        <button class="navbar-toggler border-0 m-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="offcanvas offcanvas-end bg-primary w-75 px-3 py-5" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <div class="offcanvas-title logoAC" d="offcanvasNavbarLabel"></div>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body d-flex flex-column justify-content-between my-5 py-5">
          <ul class="navbar-nav d-flex flex-column justify-content-evenly align-items-start gap-5">
            <li class="text-start fw-semibold">
              <i class="fa-solid fa-headset"></i>
              <a class="nav-link text-white" routerLink="/asistencia" routerLinkActive="active-link">Asistencia</a>
            </li>
            <!-- <li class="text-start fw-semibold">
              <i class="fa-solid fa-message"></i>
              <a class="nav-link text-white" routerLink="/feedback" routerLinkActive="active-link">Feedback</a>
            </li> -->
            <li class="text-start fw-semibold">
              <i class="fa-solid fa-circle-question"></i>
              <a class="nav-link text-white" routerLink="/faqs" routerLinkActive="active-link">FAQs</a>
            </li>
            <li class="text-start fw-semibold">
              <i class="fa-solid fa-file"></i>
              <a class="nav-link text-white" routerLink="/privacidad" routerLinkActive="active-link">Aviso de Privacidad</a>
            </li>
            <li class="text-start fw-semibold">
              <i class="fa-solid fa-file"></i>
              <a class="nav-link text-white" routerLink="/terminos" routerLinkActive="active-link">Términos y Condiciones</a>
            </li>
            <li class="text-start fw-semibold">
              <i class="fa-solid fa-file"></i>
              <a class="nav-link text-white" routerLink="/divulgacion" routerLinkActive="active-link">Divulgación</a>
            </li>
            <li class="text-start fw-semibold">
              <i class="fa-solid fa-file"></i>
              <a class="nav-link text-white" routerLink="/descargo" routerLinkActive="active-link">Descargo de responsabilidad</a>
            </li>
          </ul>
          <ul class="navbar-nav d-flex flex-column justify-content-evenly align-items-start">
            <li class="text-start fw-semibold">
              <a class="nav-link text-warning" style="cursor: pointer;" (click)="logout()"><i class="fa-solid fa-right-from-bracket text-warning"></i> Cerrar sesión</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>


<nav *ngIf="!isLoggedIn" class="navbar bg-white fixed-top shadow">
  <div class="w-100">
    <div class="container-fluid contenedor-top-logged">
      <div class="w-100 d-flex flex-row justify-content-between align-items-center">
        <a *ngIf="showBackButton" (click)="regresar()" style="cursor: pointer !important;">
          <i class="fa-solid fa-arrow-left fs-1 text-secondary" style="overflow: hidden; cursor: pointer !important;"></i>
        </a>
        <div class="navbar-brand"></div>
        <button class="navbar-toggler border-0 m-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="offcanvas offcanvas-end bg-primary w-75 px-3 py-5" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <div class="offcanvas-title logoAC" d="offcanvasNavbarLabel"></div>
          <button type="button" style="color: #FFFFFF !important;" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body d-flex flex-column justify-content-between my-5 py-5">
          <ul class="navbar-nav d-flex flex-column justify-content-evenly align-items-start gap-5">
            <li class="text-start fw-semibold">
              <a class="nav-link text-white" routerLink="/asistencia" routerLinkActive="active-link">Asistencia</a>
            </li>
            <li class="text-start fw-semibold">
              <a class="nav-link text-white" routerLink="/faqs" routerLinkActive="active-link">FAQs</a>
            </li>
          </ul>
          <ul class="navbar-nav d-flex flex-column justify-content-evenly align-items-start">
            <li class="text-start fw-semibold">
              <i class="fa-solid fa-right-to-bracket text-secondary"></i>
              <a class="nav-link text-secondary" routerLink="/login" routerLinkActive="active-link">Ir a inicio de sesión</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>

<div style="height: 6vh;"></div>