<div class="container-fluid d-flex contenedor">
    <div class="row d-flex flex-column justify-content-between align-items-center">

        <div class="d-flex flex-column justify-content-evenly align-items-center contenedor-uno aparece">
            <img src="../../../assets/logoAC_color.png" class="col-3" style="max-width: 8rem;">
            <div class="d-flex justify-content-center align-items-center contenedor-icono">
                <img src="../../../assets/onboarding02.svg" class="icono-onboarding">
                <div class="punto punto-uno"></div>
                <div class="punto punto-dos"></div>
                <div class="punto punto-tres"></div>
                <div class="punto punto-cuatro"></div>
                <div class="punto punto-cinco"></div>
            </div>
        </div>

        <div class="bg-warning d-flex flex-column justify-content-evenly align-items-center contenedor-dos aparece-dos pb-5">
            <div class="col-10 d-flex flex-column justify-content-evenly align-items-center pb-5">
                <span class="text-center text-white numeros">02</span>
                <span class="text-center mt-2 mb-4 text-primary titulo-onboarding">Monitorea los movimientos de tu cuenta</span>
                <p class="text-center text-white">Registra tus aumentos de capital e indica las fechas de retiro de tus rendimientos.</p>
                <div class="w-100 mt-4 pb-5 d-flex justify-content-between align-items-center contenedor-flecha aparece-tres">
                    <button class="mt-4 bg-primary text-white w-100 btn btn-primary d-flex justify-content-center align-items-center"  (click)="navegar()">Continuar<i class="fa-solid fa-arrow-right ms-3"></i></button>
                </div>
            </div>
        </div>
        
        
    </div>
</div>


  