import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-perfil-aumento02',
  templateUrl: './perfil-aumento02.component.html',
  styles: [
  ]
})
export class PerfilAumento02Component {

  title = 'Sube tu comprobante';
  items = [{ textoCopiar: 'Texto abajo' }, { textoCopiar: 'Texto abajo 2' }, { textoCopiar: 'Texto abajo 3' }];
  errorMessage: string = '';
  successMessage: string = '';

  constructor(
    private router: Router,
  ) {}

  copiarAlPortapapeles(elemento: HTMLParagraphElement) {
    navigator.clipboard.writeText(elemento.innerText)
      .then(() => {
        this.successMessage = 'Dato copiado en el portapapeles.';
        this.errorMessage = '';
        setTimeout(() => {
          this.successMessage = '';
        }, 3000);
      })
      .catch(err => {
        this.errorMessage = 'Error al copiar texto: ' + err;
        this.successMessage = '';
        setTimeout(() => {
          this.errorMessage = '';
        }, 3000);
      });
  }
}
