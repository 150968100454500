import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TempDataService } from '../../../../services/temp-data.service';

@Component({
  selector: 'app-perfil-nuevo01',
  templateUrl: './perfil-nuevo01.component.html',
  styles: []
})
export class PerfilNuevo01Component implements OnInit {
  title = "Nuevo contrato";
  formNuevoContrato: FormGroup;
  errorMessage: string = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private tempDataService: TempDataService // Inyecta el servicio
  ) {
    this.formNuevoContrato = new FormGroup({
      nombreContrato: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ\s]+$/)]),
      banco: new FormControl('', Validators.required),
      beneficiario: new FormControl('', Validators.required),
      nocuenta: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
      clabe: new FormControl('', [Validators.required, Validators.pattern(/^\d{18}$/)]),
      codigo_swift: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z0-9]{8,11}$/)])
    });
  }

  ngOnInit() {}

  get nombreContrato() {
    return this.formNuevoContrato.get('nombreContrato');
  }
  get banco() {
    return this.formNuevoContrato.get('banco');
  }
  get beneficiario() {
    return this.formNuevoContrato.get('beneficiario');
  }
  
  get nocuenta() {
    return this.formNuevoContrato.get('nocuenta');
  }
  
  get clabe() {
    return this.formNuevoContrato.get('clabe');
  }
  
  get codigo_swift() {
    return this.formNuevoContrato.get('codigo_swift');
  }

  onSubmit() {
    if (this.formNuevoContrato.valid) {
      const dataToSend = {
        nombreCuenta: this.formNuevoContrato.value.nombreContrato,
        banco: this.formNuevoContrato.value.banco,
        beneficiario: this.formNuevoContrato.value.beneficiario,
        nocuenta: this.formNuevoContrato.value.nocuenta,
        clabe: this.formNuevoContrato.value.clabe,
        codigo_swift: this.formNuevoContrato.value.codigo_swift
      };
  
      this.http.post('https://login.arcsacapital.com/contratos_banco', dataToSend)
        .subscribe({
          next: (response) => {
            console.log('Datos enviados correctamente', response);
            this.tempDataService.setFormDatosPersonales({ nombreCuenta: this.formNuevoContrato.value.nombreContrato });
            this.router.navigate(['/perfil-nuevo03']);
          },
          error: (error) => {
            console.error('Error al enviar los datos', error);
            this.errorMessage = 'Error al enviar los datos: ' + error.message;
          }
        });
    } else {
      this.errorMessage = 'Por favor, complete todos los campos requeridos correctamente.';
    }
  }
}