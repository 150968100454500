<app-user-status></app-user-status>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">

        <div class="col-11 d-flex flex-column justify-content-center align-items-center progresoRegistroCaja aparece" style="--porcentaje: 10">
            <div class="col-11 d-flex justify-content-start align-items-center">
                <p class="text-start m-0 me-1 fs-1 fw-regular">Paso</p>
                <button type="button" class="bg-transparent border-0 d-flex justify-content-center align-items-center" style="height: 2.2rem; width: 2.2rem;" data-bs-toggle="modal" data-bs-target="#modal">
                    <i class="fa-solid fa-circle-info text-primary fs-5 p-1"></i>
                </button>
                <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                        <div class="container">
                            <div class="row d-flex justify-content-center align-items-center">
                                <div class="col-10">
                                    <div class="card">
                                        <div class="card-body p-5">
                                            <div class="cont-btn-close">
                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                  <i class="fa-solid fa-x"></i>
                                                </button>
                                            </div>
                                            <h4 class="card-title text-center text-primary">Pasos</h4>
                                            <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-11 d-flex justify-content-start align-items-center cajaTexto">
                <span class="me-2">5</span>
                <span> de 5</span>
            </div>
            <div class="col-11" style="--porcentaje: 50">
                <svg class="w-75 m-0 progresoRegistro">
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="50" />
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="50" />
                </svg>
            </div>
            
        </div>

        <div class="col-11 mb-5 progresoRegistroCaja aparece-dos">

            <form [formGroup]="formPromesa" (ngSubmit)="onSubmit()" class="d-flex flex-column justify-content-center align-items-center">
                
                <div class="col-11">
                  <h1>{{ title }}</h1>
                  <p style="transform: none !important;">Es necesario firmar la promesa de inversión antes de continuar.</p>
                </div>
              
                <div class="col-11 mb-3 form-check d-flex align-items-center">
                    <input class="form-check-input input_check" type="checkbox" id="acepta_compromiso" formControlName="acepta_compromiso" required>
                    <label class="form-check-label label_check" for="acepta_compromiso">Acepto la promesa de inversión</label>
                  </div> 
              
                <div *ngIf="errorMessage" class="col-11 alert alert-danger">
                  {{ errorMessage }}
                </div>
              
                <div *ngIf="successMessage" class="col-11 alert alert-success">
                  {{ successMessage }}
                </div>
              
                <div class="col-12">
                  <button class="btn btn-primary" type="submit" [disabled]="formPromesa.invalid">Enviar</button>
                </div>
            </form>
  
              <div class="w-100 my-5 d-flex flex-column justify-content-center align-items-center aparece-tres">
                  <a class="col-12 text-center my-5" routerLink="/perfil-nuevo07" routerLinkActive="active-link">"Avanzar con la prueba"</a>
              </div>
  
          </div>

    </div>
</div>

