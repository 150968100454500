<app-user-status [showBackButton]="true"></app-user-status>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">

        <div class="col-11 d-flex flex-column justify-content-center align-items-center progresoRegistroCaja aparece" style="--porcentaje: 10">
            <div class="col-11">
                <h1>{{ title }}</h1>
            </div>
        </div>

        <div class="col-11 aparece">
            
            <div class="px-4 py-2 m-3 rounded campo-asist shadow">
                <div class="d-flex justify-content-between align-items-end my-2">
                    <div>
                        <p class="m-0 p-0">Tel:</p>
                        <p #textoCopiar class="m-0 p-0 text-primary fw-bold">55 2298 4320</p>
                    </div>
                    <div>
                        <button (click)="copiarAlPortapapeles(textoCopiar)">
                            <i class="fa-regular fa-copy text-secondary" style="overflow: hidden;"></i>
                        </button>
                    </div>
                </div>
            </div>
            
            <div class="px-4 py-2 m-3 rounded campo-asist shadow ">
                <div class="d-flex justify-content-between align-items-end my-2">
                    <div>
                        <p class="m-0 p-0">Mail:</p>
                        <p #textoCopiarDos class="m-0 p-0 text-primary fw-bold">inversionistas&#64;arcsacapital.com</p>
                    </div>
                    <div>
                        <button (click)="copiarAlPortapapeles(textoCopiarDos)">
                            <i class="fa-regular fa-copy text-secondary" style="overflow: hidden;"></i>
                        </button>
                    </div>
                </div>
            </div>
            
            <div class="d-flex pb-2">
                <div class="w-50 px-4 py-2 m-3 rounded campo-asist shadow">
                    <a class="d-flex justify-content-center align-items-center my-2" style="text-decoration: none !important;" href="{{ linksService.whatsappUrl }}" target="_blank">
                        <i class="fa-brands fa-whatsapp text-primary" style="font-size: 3.4rem !important; overflow: hidden !important;"></i>
                    </a>
                </div>
                
                <div class="w-50 px-4 py-2 m-3 rounded campo-asist shadow">
                    <a class="d-flex justify-content-center align-items-center my-2" style="text-decoration: none !important;" href="{{ linksService.linkedinUrl }}" target="_blank">
                        <i class="fa-brands fa-linkedin text-primary" style="font-size: 3.4rem !important; overflow: hidden !important;"></i>
                    </a>
                </div>
            </div>
            
            <div class="d-flex pb-2">
                <div class="w-50 px-4 py-2 m-3 rounded campo-asist shadow">
                    <a class="d-flex justify-content-center align-items-center my-2" style="text-decoration: none !important;" href="{{ linksService.instagramUrl }}" target="_blank">
                        <i class="fa-brands fa-instagram text-primary" style="font-size: 3.4rem !important; overflow: hidden !important;"></i>
                    </a>
                </div>
                
                <div class="w-50 px-4 py-2 m-3 rounded campo-asist shadow">
                    <a class="d-flex justify-content-center align-items-center my-2" style="text-decoration: none !important;" href="{{ linksService.threadsUrl }}" target="_blank">
                        <i class="fa-brands fa-threads text-primary" style="font-size: 3.4rem !important; overflow: hidden !important;"></i>
                    </a>
                </div>
            </div>
            
            <div class="d-flex pb-2">
                <div class="w-50 px-4 py-2 m-3 rounded campo-asist shadow">
                    <a class="d-flex justify-content-center align-items-center my-2" style="text-decoration: none !important;" href="{{ linksService.facebookUrl }}" target="_blank">
                        <i class="fa-brands fa-facebook text-primary" style="font-size: 3.4rem !important; overflow: hidden !important;"></i>
                    </a>
                </div>
                
                <div class="w-50 px-4 py-2 m-3 rounded campo-asist shadow">
                    <a class="d-flex justify-content-center align-items-center my-2" style="text-decoration: none !important;" href="{{ linksService.youtubeUrl }}" target="_blank">
                        <i class="fa-brands fa-youtube text-primary" style="font-size: 3.4rem !important; overflow: hidden !important;"></i>
                    </a>
                </div>
            </div>

            <div class="px-4 py-2 m-3 rounded campo-asist shadow ">
                <a class="d-flex justify-content-between align-items-end my-2" style="text-decoration: none;" routerLink="/faqs" routerLinkActive="active-link">
                    <div>
                        <p #textoCopiarDos class="m-0 p-0 text-primary fw-bold">FAQs</p>
                    </div>
                    <div>
                        <i class="fa-solid fa-angle-right text-primary" style="overflow: hidden;"></i>
                    </div>
                </a>
            </div>

        </div>

        <div class="col-11 mb-1" style="height: 4rem; overflow: hidden;">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="col-11">
                    <div *ngIf="successMessage" class="w-100 alert alert-success desaparece-modal">
                        {{successMessage}}
                    </div>
                    <div *ngIf="errorMessage" class="alert alert-danger desaparece-modal">
                        {{errorMessage}}
                    </div>
                </div>
            </div>
        </div>

          
        <div class="w-100 my-5 d-flex flex-column justify-content-center align-items-center aparece-tres">
            <a class="col-12 text-center my-5" routerLink="/preregistro01" routerLinkActive="active-link">"Regresar"</a>
        </div>

    </div>
</div>
