import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/logged/auth.service';

@Component({
  selector: 'app-preregistro05',
  templateUrl: './preregistro05.component.html',
  styleUrls: []
})
export class Preregistro05Component {

  constructor (
    private router: Router,
    private authService: AuthService,
    )
  {}

  logout(): void {
    this.authService.logout();
  }

}
