import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

@Component({
  selector: 'app-perfil-retiro01',
  templateUrl: './perfil-retiro01.component.html',
  styleUrls: []
})
export class PerfilRetiro01Component implements OnInit {
  title = 'Retirar Rendimientos';
  formDatos: FormGroup;
  nombreCuenta: string = '';
  errorMessage: string = '';
  successMessage: string = '';
  isLoading: boolean = false;
  envioExitoso: boolean = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router
  ) {
    this.formDatos = this.fb.group({
      montoRetiro: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]]
    });
  }

  ngOnInit(): void {
    const nombreCuentaStored = localStorage.getItem('nombreCuenta');
    this.nombreCuenta = nombreCuentaStored ? nombreCuentaStored : '';
  }

  retirarRendimientos() {
    if (this.formDatos.invalid) {
      this.errorMessage = 'Por favor, ingrese un monto de retiro válido.';
      return;
    }

    this.isLoading = true;
    const url = 'https://login.arcsacapital.com/retiros';
    const body = {
      nombreCuenta: this.nombreCuenta,
      monto_retiro: parseFloat(this.formDatos.get('montoRetiro')?.value)
    };

    this.http.post(url, body, { observe: 'response' })
      .pipe(
        tap((response: HttpResponse<any>) => {
          this.successMessage = 'Datos insertados correctamente';
          this.envioExitoso = true;
          this.isLoading = false;
          this.router.navigate(['/perfil-retiro02']);
        }),
        catchError((error: HttpErrorResponse) => {
          this.isLoading = false;
          if (error.status === 500) {
            this.errorMessage = 'Error 500, pero procediendo con el flujo.';
          } else {
            this.errorMessage = error.error.message || 'Hubo un error al enviar tus datos. Por favor, inténtalo de nuevo.';
          }
          return of(null); // Finaliza el observable sin emitir un error
        })
      )
      .subscribe();
  }
}
