<div class="container">
  <div class="row d-flex justify-content-center">
    <div class="col-10 d-flex flex-column justify-content-center align-items-center mt-1">

      <h1 class="text-center mt-5">404 - NotFound</h1>

      <h4 class="text-center mt-5">Ir al HOME</h4>
      
      <button routerLink="/home" routerLinkActive="active-link" class="btn btn-primary mt-2">Ir</button>

    </div>
  </div>
</div>