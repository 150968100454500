import { Component } from '@angular/core';
import { AuthService } from '../../../services/logged/auth.service';

@Component({
  selector: 'app-contrato02',
  templateUrl: './contrato02.component.html',
  styles: [
  ]
})
export class Contrato02Component {

  constructor(
    private authService: AuthService,
  ) {}

  logout(): void {
    this.authService.logout();
  }

}
