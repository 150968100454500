import { Component } from '@angular/core';

@Component({
  selector: 'app-perfil-nuevo07',
  templateUrl: './perfil-nuevo07.component.html',
  styles: [
  ]
})
export class PerfilNuevo07Component {

}
