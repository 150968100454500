<app-menu-preregistro></app-menu-preregistro>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">

        <div class="col-11 d-flex flex-column justify-content-center align-items-center progresoRegistroCaja aparece" style="--porcentaje: 10">
            <div class="col-11 d-flex justify-content-start align-items-center">
                <p class="text-start m-0 me-1 fs-1 fw-regular">Paso</p>
                <button type="button" class="bg-transparent border-0 d-flex justify-content-center align-items-center" style="height: 2.2rem; width: 2.2rem;" data-bs-toggle="modal" data-bs-target="#modal">
                    <i class="fa-solid fa-circle-info text-primary fs-5 p-1"></i>
                </button>
                <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                        <div class="container">
                            <div class="row d-flex justify-content-center align-items-center">
                                <div class="col-10">
                                    <div class="card">
                                        <div class="card-body p-5">
                                            <div class="cont-btn-close">
                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                  <i class="fa-solid fa-x"></i>
                                                </button>
                                            </div>
                                            <h4 class="card-title text-center text-primary">Pasos</h4>
                                            <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-11 d-flex justify-content-start align-items-center cajaTexto">
                <span class="me-2">4</span>
                <span> de 7</span>
            </div>
            <div class="col-11" style="--porcentaje: 40">
                <svg class="w-75 m-0 progresoRegistro">
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="70" />
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="70" />
                </svg>
            </div>
            
        </div>

        <div class="col-11 mb-5 progresoRegistroCaja aparece-dos">

          <form class="d-flex flex-column justify-content-center align-items-center" id="formBancario" [formGroup]="formBancario" (ngSubmit)="onSubmit()">

            <div class="col-11 aparece">
              <h1>{{ title }}</h1>
            </div>
    
            <div class="col-11 aparece-dos">
            
                <p>Ingresa los datos de la cuenta bancaria en la que se depositarán tus rendimientos.</p>
                <p class="me-4 mt-1 mb-4 fs-6 text-primary fst-italic">* Campos obligatorios</p>
                
                <div>
                    <label class="form-label" for="banco">*Banco / Entidad:</label>
                    <div class="d-flex" style="position: relative;">
                        <input class="form-control mb-0 me-2" type="text" id="banco" formControlName="banco" required>
                        <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalDos">
                            <i class="fa-solid fa-circle-info text-primary p-1"></i>
                        </button>
                        <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalDos" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                <div class="container">
                                    <div class="row d-flex justify-content-center align-items-center">
                                        <div class="col-10">
                                            <div class="card">
                                                <div class="card-body p-5">
                                                    <div class="cont-btn-close">
                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                  <i class="fa-solid fa-x"></i>
                                                </button>
                                            </div>
                                                    <h4 class="card-title text-center text-primary">Banco</h4>
                                                    <p class="card-text text-center">Info adicional</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-danger" [hidden]="banco?.['valid'] || banco?.['pristine']">
                        <span *ngIf="banco?.errors?.['required']">Este campo no puede quedar vacío</span>
                        <span *ngIf="banco?.errors?.['pattern']">Solo puede contener letras</span>
                    </div>
                
                    <label class="form-label" for="beneficiario">*Nombre del Beneficiario:</label>
                    <div class="d-flex" style="position: relative;">
                        <input class="form-control mb-0 me-2" type="text" id="beneficiario" formControlName="beneficiario" required>
                        <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalTres">
                            <i class="fa-solid fa-circle-info text-primary p-1"></i>
                        </button>
                        <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalTres" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                <div class="container">
                                    <div class="row d-flex justify-content-center align-items-center">
                                        <div class="col-10">
                                            <div class="card">
                                                <div class="card-body p-5">
                                                    <div class="cont-btn-close">
                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                  <i class="fa-solid fa-x"></i>
                                                </button>
                                            </div>
                                                    <h4 class="card-title text-center text-primary">Beneficiario</h4>
                                                    <p class="card-text text-center">Información adicional</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-danger" [hidden]="beneficiario?.['valid'] || beneficiario?.['pristine']">
                        <span *ngIf="beneficiario?.errors?.['required']">Este campo no puede quedar vacío</span>
                        <span *ngIf="beneficiario?.errors?.['pattern']">Solo puede contener letras</span>
                    </div>
                    
                    <label class="form-label" for="nocuenta">*Número de cuenta:</label>
                    <div class="d-flex" style="position: relative;">
                        <input class="form-control mb-0 me-2" type="text" id="nocuenta" formControlName="nocuenta" required>
                        <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalCuatro">
                            <i class="fa-solid fa-circle-info text-primary p-1"></i>
                        </button>
                        <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalCuatro" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                <div class="container">
                                    <div class="row d-flex justify-content-center align-items-center">
                                        <div class="col-10">
                                            <div class="card">
                                                <div class="card-body p-5">
                                                    <div class="cont-btn-close">
                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                  <i class="fa-solid fa-x"></i>
                                                </button>
                                            </div>
                                                    <h4 class="card-title text-center text-primary">Número de cuenta</h4>
                                                    <p class="card-text text-center">Información adicional</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-danger" [hidden]="nocuenta?.['valid'] || nocuenta?.['pristine']">
                        <span *ngIf="nocuenta?.errors?.['required']">Este campo no puede quedar vacío</span>
                        <span *ngIf="nocuenta?.errors?.['pattern']">Solo puede contener letras</span>
                    </div>
                    
                    <label class="form-label" for="clabe">*Cuenta CLABE:</label>
                    <div class="d-flex" style="position: relative;">
                        <input class="form-control mb-0 me-2" type="text" id="clabe" formControlName="clabe" required>
                        <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalCinco">
                            <i class="fa-solid fa-circle-info text-primary p-1"></i>
                        </button>
                        <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalCinco" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                <div class="container">
                                    <div class="row d-flex justify-content-center align-items-center">
                                        <div class="col-10">
                                            <div class="card">
                                                <div class="card-body p-5">
                                                    <div class="cont-btn-close">
                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                  <i class="fa-solid fa-x"></i>
                                                </button>
                                            </div>
                                                    <h4 class="card-title text-center text-primary">CLABE</h4>
                                                    <p class="card-text text-center">Información adicional</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-danger" [hidden]="clabe?.['valid'] || clabe?.['pristine']">
                        <span *ngIf="clabe?.errors?.['required']">Este campo no puede quedar vacío</span>
                        <span *ngIf="clabe?.errors?.['pattern']">Solo puede contener letras</span>
                    </div>
                    
                    <label class="form-label" for="codigo_swift">*Código SWIFT:</label>
                    <div class="d-flex" style="position: relative;">
                        <input class="form-control mb-0 me-2" type="text" id="codigo_swift" formControlName="codigo_swift" required>
                        <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalSeis">
                            <i class="fa-solid fa-circle-info text-primary p-1"></i>
                        </button>
                        <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalSeis" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                <div class="container">
                                    <div class="row d-flex justify-content-center align-items-center">
                                        <div class="col-10">
                                            <div class="card">
                                                <div class="card-body p-5">
                                                    <div class="cont-btn-close">
                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                  <i class="fa-solid fa-x"></i>
                                                </button>
                                            </div>
                                                    <h4 class="card-title text-center text-primary">SWIFT</h4>
                                                    <p class="card-text text-center">Información adicional</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-danger" [hidden]="codigo_swift?.['valid'] || codigo_swift?.['pristine']">
                        <span *ngIf="codigo_swift?.errors?.['required']">Este campo no puede quedar vacío</span>
                        <span *ngIf="codigo_swift?.errors?.['pattern']">Solo puede contener letras</span>
                    </div>

                    <div *ngIf="errorMessage" class="w-100 mt-2 alert alert-danger">
                        {{ errorMessage }}
                    </div>
                    
                    <div *ngIf="successMessage" class="w-100 mt-2 alert alert-success">
                        {{ successMessage }}
                    </div>

                </div>
            </div>
    
                <div class="col-12 contenedor-botones aparece-tres">
                <div class="col-11">
                    <button class="btn btn-primary w-100" type="submit" [disabled]="formBancario.invalid">Enviar</button>
                </div>
                </div>
              
            </form>

            <div class="w-100 my-5 d-flex flex-column justify-content-center align-items-center aparece-tres">
                <a class="col-12 text-center my-5" routerLink="/simuladorDatos" routerLinkActive="active-link">"Avanzar con la prueba"</a>
            </div>

        </div>
    </div>
</div>
