import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/logged/auth.service';
import { NavegacionService } from '../../../services/navegacion.service';

@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: [],
})
export class UserStatusComponent implements OnInit, OnDestroy {
  
  isLoggedIn = false;
  nombreUsuario: string | undefined;
  private subscription: Subscription = new Subscription();
  @Input() showBackButton: boolean = false;

  constructor(
    private authService: AuthService,
    private navegacionService: NavegacionService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.authService.obtenerDatosUsuario().subscribe(
      user => {
        console.log('Datos del usuario actualizados:', user);
        this.isLoggedIn = !!user;
        if (typeof user.nombre_usuario.nombre_usuario === 'string') {
          this.nombreUsuario = user.nombre_usuario.nombre_usuario;
        }
      },
      error => {
        this.isLoggedIn = false;
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  logout(): void {
    this.authService.logout();
  }

  regresar(): void {
    this.navegacionService.goBack();
  }
}