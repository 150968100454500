import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nuevacontrasena',
  templateUrl: './nuevacontrasena.component.html',
  styleUrls: ['./nuevacontrasena.component.css']
})
export class NuevacontrasenaComponent {

  constructor (private router: Router){ }

  regresar(){
    this.router.navigate(['/login']);
  };
  
  iniciarSesion(){
    this.router.navigate(['/login']);
  };
  
  crearCuenta(){
    this.router.navigate(['/preregistro02']);
  };
  
  temporal(){
    this.router.navigate(['/mensaje01']);
  };

}
