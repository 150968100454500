import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../logged/auth.service';

export type SeccionKey = 'seccion1' | 'seccion2' | 'seccion3' | 'seccion4' | 'seccion5' | 'seccion6' | 'seccion7' | 'seccion8' | 'seccion9' | 'seccion10' | 'seccion11' | 'seccion12';

@Injectable({
  providedIn: 'root'
})
export class ProgresoUsuarioService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {}

  public checkInitialStatusAndProgress() {
    this.http.get<any[]>('https://login.arcsacapital.com/simulador_get').subscribe({
        next: (response) => {
            if (response.length > 0) {
                const status = response[0].status;
                
                if (status === "1") {
                    this.checkUserProgressAndNavigate();
                } else {
                    this.router.navigate(['/simulador']);
                }
            } else {
                this.router.navigate(['/simulador']);
            }
        },
        error: (error) => {
            this.router.navigate(['/error']);
        }
    });
  }

  public checkUserProgressAndNavigate() {
    this.http.get<{[key in SeccionKey]?: number}>('https://login.arcsacapital.com/progreso_usuario').subscribe({
      next: (response) => {
        this.navigateBasedOnProgress(response);
      },
      error: (error) => {
        console.error('Error fetching the last edited section:', error);
        if (error.status === 401 || error.status === 403) {
          this.router.navigate(['/login']);
        } else {
          this.router.navigate(['/registro01']);
        }
      }
    });
  }

  private navigateBasedOnProgress(data: {[key in SeccionKey]?: number}) {
    const secciones: {[key in SeccionKey]: string} = {
        seccion1: '/registro01',
        seccion2: '/registro03',
        seccion3: '/registro04',
        seccion4: '/registro05',
        seccion5: '/simuladorDatos',
        seccion6: '/registro06',
        seccion7: '/registro08',
        seccion8: '/status01',
        seccion9: '/comprobante01',
        seccion10: '/status02',
        seccion11: '/contrato01',
        seccion12: '/status03',
    };

    const ordenSecciones: SeccionKey[] = ['seccion1', 'seccion2', 'seccion3', 'seccion4', 'seccion5', 'seccion6', 'seccion7', 'seccion8', 'seccion9', 'seccion10', 'seccion11', 'seccion12'];
    const ultimaSeccionCompletada = ordenSecciones.find(key => data[key] === 1);

    if (ultimaSeccionCompletada) {
        const index = ordenSecciones.indexOf(ultimaSeccionCompletada);
        if (index === ordenSecciones.length - 1) { // Confirma que la sección 12 es la última completada
            this.router.navigate(['/perfil01']);
        } else {
            const siguienteSeccion = ordenSecciones[index + 1];
            this.router.navigate([secciones[siguienteSeccion]]);
        }
    } else {
        this.router.navigate([secciones['seccion1']]); // Si ninguna sección está completada, redirige a la primera sección
    }
}
}