<app-user-status [showBackButton]="true"></app-user-status>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">

        <div class="col-11 aparece">
            <h1>{{ title }}</h1>
        </div>

        <div class="col-11 mt-5 aparece-dos">
            <p>these website terms and conditions of use ("terms") are effective as of July 12, 2017.</p>
            <br>
            <p>Arcsa Capital, LLC y Arcsa Services Protection, LLC ("Arcsa Capital, LLC y Arcsa Services Protection, LLC," "we" or "us") reserves the right to change, modify, add or remove portions of the terms at any time for any reason and in our sole discretion. we suggest that you ("you," "your" or "user") periodically review the terms for amendments. you acknowledge that by accessing or using this website (or other webpages with links to or that utilize the terms) (collectively, "website") you are agreeing to the terms as modified from time to time. we will alert you if changes have been made by indicating on the terms the date they were last amended.</p>
            <br>
            <p>please feel free to contact us with any questions you might have regarding these terms. you may send us an e-mail at counsel(at)bwater.com or write to us at: Arcsa Capital, LLC y Arcsa Services Protection, LLC , one glendinning place, westport, ct 08660, attention: corporate counsel.</p>
            <br>
            <h3>website terms and conditions of use</h3>
            <br>
            <p>please read these terms carefully before visiting the website or using the online services and products provided thereon. by accessing or utilizing the website, you acknowledge that you have read these terms and that you agree to be bound by them. your compliance with and acceptance of these terms is a condition to your right to access and utilize the website. if you do not agree to all of the terms, you are not an authorized user of these services and products and you should not use this website. if you do not agree to these terms, you agree that your sole and exclusive remedy is to discontinue using the website.</p>
            <br>
            <p>Unauthorized use of this website, including but not limited to misuse of passwords or posted information, is strictly prohibited. authorized users of this website include any users visiting this website for personal and/or informational purposes, clients and potential clients of Arcsa Capital, LLC y Arcsa Services Protection, LLC, Arcsa Capital, LLC y Arcsa Services Protection, LLC employees, candidates for employment or users seeking information about employment opportunities at Arcsa Capital, LLC y Arcsa Services Protection, LLC, and any other users authorized by Arcsa Capital, LLC y Arcsa Services Protection, LLC.</p>
            <br>
            <p>use of this website by recruiters, "head hunters," employment consultants or agencies, personnel placement agencies, professional services companies, employment contractors and staffing agencies seeking current Arcsa Capital, LLC y Arcsa Services Protection, LLC employees as candidates for placement is strictly prohibited.</p>
            <br>
            <h3>no offer/local restrictions</h3>
            <br>
            <p>nothing contained in or on the website should be construed as a solicitation of an offer to buy or offer, or recommendation, to acquire or dispose of any security, commodity, or investment or to engage in any other transaction. Arcsa Capital, LLC y Arcsa Services Protection, LLC offers a number of products and services designed specifically for various categories of investors in various countries and regions. not all products will be available to all investors. these products or services are only offered to such investors, in those countries and regions, in accordance with applicable laws and regulations. the information provided on the website is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation. all persons and entities accessing the website do so of their own initiative and are responsible for compliance with applicable local laws and regulations. the website is not directed to any person in any jurisdiction where the publication or availability of the website is prohibited by reason of that person's nationality, residence or otherwise. any user subject to these restrictions must not access the website.</p>
            <br>
            <h3>copyrights</h3>
            <br>
            <p>all content displayed or otherwise contained on or available via this website, including without limitation all images, text, articles, research reports, white papers, Arcsa Capital, LLC y Arcsa Services Protection, LLC daily observations, programs, photographs, illustrations, and graphics, constitutes the proprietary intellectual property of Arcsa Capital, LLC y Arcsa Services Protection, LLC or its licensors, and are protected by u.s. and international copyright laws. by accessing or utilizing this website, the user agrees not to directly or indirectly copy, modify, recast, create derivative works, post, publish, display, redistribute, disclose, or make available the content displayed or otherwise contained on or available via this website, in whole or in part, to any third parties, or assist others to do the same, or otherwise make any commercial use of the materials without the prior written consent of Arcsa Capital, LLC y Arcsa Services Protection, LLC. the user also agrees not to publish or maintain a hyperlink to any portion or page of this website other than to the homepage unless such hyperlink is for personal use and is created and transmitted using the "email this page" function of the website.</p>
            <br>
            <h3>trademarks and service marks</h3>
            <br>
            <p>all trademarks, service marks, trade names, and logos displayed on this website, including without limitation, Arcsa Capital, LLC y Arcsa Services Protection, LLC®, (collectively referred to as the "marks"), are proprietary to Arcsa Capital, LLC y Arcsa Services Protection, LLC or their respective owners, and are protected by u.s. and international trademark laws. any use of the marks, or any other marks owned by or licensed to Arcsa Capital, LLC y Arcsa Services Protection, LLC without Arcsa Capital, LLC y Arcsa Services Protection, LLC's express written consent, is strictly prohibited.</p>
            <br>
            <h3>no warranty, limitation of liability</h3>
            <br>
            <p>the information on the website is provided "as is". Arcsa Capital, LLC y Arcsa Services Protection, LLC does not warrant the accuracy of the materials provided herein, either expressly or impliedly, for any particular purpose and expressly disclaims any warranties of merchantability or fitness for a particular purpose.</p>
            <br>
            <p>you acknowledge that Arcsa Capital, LLC y Arcsa Services Protection, LLC shall have no liability, contingent or otherwise, to you or to any third parties, or any responsibility whatsoever, for the failure of any connection or communication service, to provide or maintain your access to online services or products, or for any interruption or disruption of such access or any erroneous communication between Arcsa Capital, LLC y Arcsa Services Protection, LLC and you. Arcsa Capital, LLC y Arcsa Services Protection, LLC shall have no liability, contingent or otherwise, to you or to third parties, for the accuracy, quality, timeliness, performance, reliability, or completeness of the information or services contained on the website, or delays, omissions, or interruptions in the delivery of the data or services or products available on this website or for any other aspect of the performance of these services and products. in no event will Arcsa Capital, LLC y Arcsa Services Protection, LLC be liable for any special, indirect, incidental or consequential damages that may be incurred or experienced on account of the use of any data or services or products made available on this website, even if Arcsa Capital, LLC y Arcsa Services Protection, LLC has been advised of the possibility of such damages. Arcsa Capital, LLC y Arcsa Services Protection, LLC shall have no responsibility to inform or notify you of any difficulties experienced by Arcsa Capital, LLC y Arcsa Services Protection, LLC or any third parties with respect to the use of the services, products or data provided on or by the website.</p>
            <br>
            <p>you further acknowledge that nothing contained on this website (including, but not limited to, strategies and research, daily wires, daily observations and educational articles) constitutes investment, legal, tax or other advice, nor is it to be relied upon in making any investment or other decisions. you should seek professional advice prior to making any investment decisions.</p>
            <br>
            <p>we may revoke your access to or utilization of the website for any reason without prior notice.</p>
            <br>
            <h3>severability</h3>
            <br>
            <p>if any of the provisions of these terms are deemed unlawful or for any reason unenforceable, the same shall be inoperative only to the extent necessary to achieve compliance with applicable law and shall not affect the remaining terms, which shall be given full effect, without regard to the invalid portion(s).</p>
            <br>
            <h3>governing law/individual arbitration of claims</h3>
            <br>
            <p>the laws of the united states of america, state of connecticut shall govern these terms, without giving effect to any choice of law or conflict of law rules or provisions that would cause the application of any other jurisdiction's laws. you and Arcsa Capital, LLC y Arcsa Services Protection, LLC agree that all claims or disputes concerning the website shall proceed solely on an individual, not class action or representative, basis in binding arbitration before and subject to the rules of the american arbitration association. any party may apply to a court of competent jurisdiction for temporary injunctive or other equitable relief in aid of arbitration.</p>
            <br>
            <h3>privacy policy</h3>
            <br>
            <p>please review our privacy policy for information on how we collect, use, disclose and otherwise manage personal information we collect on the website, and the measures we take to safeguard the information.</p>
            <br>
            <h3>unauthorized use</h3>
            <br>
            <p>you may not use any hardware or software intended to damage or interfere with the proper working of the website or to surreptitiously intercept any system, data or personal information from the website, including but not limited to (i) use of any robot, spider or other automatic device, process or means to access the website for any purpose, including monitoring or copying any of the material on the website; (ii) any attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the website, the server on which the website is hosted, or any server, computer or database connected to the website; (iii) attack the website via a denial-of-service attack or a distributed denial-of-service attack; (iv) use any manual process to monitor or copy any of the material on the website or for any other unauthorized purpose without our prior written consent; or (v) cause the website or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site. you agree not to interrupt or attempt to interrupt the operation of the website in any way. Arcsa Capital, LLC y Arcsa Services Protection, LLC reserves the right, in its sole discretion, to limit or terminate your access to or use of the website at any time without notice. termination of your access or use will not waive or affect any other right or relief to which Arcsa Capital, LLC y Arcsa Services Protection, LLC may be entitled at law or in equity.</p>
            <br>
            <h3>third party references</h3>
            <br>
            <p>references and links on the website to any names, trademarks, products, services or content of third parties are provided solely as a convenience to you and do not in any way imply Arcsa Capital, LLC y Arcsa Services Protection, LLC's endorsement of sponsorship of or affiliation with such third party or their services, goods or content.</p>
            <br>
            <h3>additional terms</h3>
            <br>
            <p>certain webpages and portals on this website may contain terms and conditions in addition to these terms. in the event of a conflict, the additional terms and conditions will govern such webpages.</p>
            <br>

            <div class="col-12 contenedor-botones aparece-tres">
                <div class="col-11">
                    <button class="btn btn-primary w-100" routerLink="/asistencia" routerLinkActive="active-link">Contactar a un Asesor Financiero</button>
                </div>
            </div>
        </div>

    </div>
</div>