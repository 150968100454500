<app-user-status [showBackButton]="true"></app-user-status>

<div class="container-fluid contenedor-94 mt-5 pt-5 d-flex flex-column justify-content-center">
    <div class="row pb-5 d-flex justify-content-evenly align-items-center">
        <div class="col-11 pb-5">
            
            <form class="d-flex flex-column justify-content-center align-items-center">
        
                <div class="col-11 aparece">
                    <h1>{{ title }}</h1>
                </div>
        
                <div class="col-11 mt-3 mb-2 aparece-dos">
                    <p>Te enviaremos un código para restablecer tu contraseña</p>
                    <label class="form-label" for="email">Email:</label>
                    <input class="form-control" type="email" id="email" formControlName="email" required>
                </div>
                

                <div class="col-11 contenedor-botones aparece-tres">
                    <button class="w-100 btn btn-primary" (click)="temporal()" >Enviar</button>
                    <a class="d-flex justify-content-center mt-3 link-underline link-underline-opacity-0" routerLink="/login" routerLinkActive="active-link">Iniciar sesión</a>
                    <a class="d-flex justify-content-center mt-3 link-underline link-underline-opacity-0" routerLink="/preregistro02" routerLinkActive="active-link">Crear cuenta</a>            
                </div>
                    
            </form>
        </div>
    </div>
</div>

