import { Component } from '@angular/core';
import { LinksService } from '../../../services/links.service';

@Component({
  selector: 'app-asistencia',
  templateUrl: './asistencia.component.html',
  styleUrls: []
})
export class AsistenciaComponent {
  
  title = 'Asistencia';
  items = [{textoCopiar: 'Texto abajo'}, {textoCopiar: 'Texto abajo 2'}, {textoCopiar: 'Texto abajo 3'}];
  errorMessage: string = '';
  successMessage: string = '';
  
  constructor(
    public linksService: LinksService
    ) { }

  copiarAlPortapapeles(elemento: HTMLParagraphElement) {
    navigator.clipboard.writeText(elemento.innerText)
      .then(() => {
        this.successMessage = 'Dato copiado en el portapapeles.';
        this.errorMessage = '';
        setTimeout(() => {
          this.successMessage = '';
        }, 3000);
      })
      .catch(err => {
        this.errorMessage = 'Error al copiar texto: ' + err;
        this.successMessage = '';
        setTimeout(() => {
          this.errorMessage = '';
        }, 3000);
      });
  }

}
