import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboarding03',
  templateUrl: './onboarding03.component.html',
  styleUrls: ['./onboarding03.component.css']
})
export class Onboarding03Component {

  constructor (private router: Router){ }

  navegarAnt(){
    this.router.navigate(['/onboarding02']);
  };
  
  navegar(){
    this.router.navigate(['/onboarding04']);
  };


}
