import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TempDataService {
  private formDatosPersonales = new BehaviorSubject<any>(this.getStoredItem('formDatosPersonales'));
  private formBeneficiarios = new BehaviorSubject<any>(this.getStoredItem('formBeneficiarios'));
  private formAumentoInversion = new BehaviorSubject<any>(this.getStoredItem('formAumentoInversion'));

  private getStoredItem(key: string): any {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  private setStoredItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  setFormDatosPersonales(data: any) {
    this.formDatosPersonales.next(data);
    this.setStoredItem('formDatosPersonales', data);
  }

  getFormDatosPersonales() {
    return this.formDatosPersonales.asObservable();
  }

  getCurrentDatosPersonales() {
    return this.formDatosPersonales.getValue();
  }

  setFormBeneficiarios(data: any) {
    this.formBeneficiarios.next(data);
    this.setStoredItem('formBeneficiarios', data);
  }

  getFormBeneficiarios() {
    return this.formBeneficiarios.asObservable();
  }

  getCurrentBeneficiarios() {
    return this.formBeneficiarios.getValue();
  }

  setFormAumentoInversion(data: any) {
    this.formAumentoInversion.next(data);
    this.setStoredItem('formAumentoInversion', data);
  }

  getFormAumentoInversion() {
    return this.formAumentoInversion.asObservable();
  }

  getCurrentAumentoInversion() {
    return this.formAumentoInversion.getValue();
  }

  clearFormDatosPersonales() {
    this.formDatosPersonales.next(null);
    localStorage.removeItem('formDatosPersonales');
  }

  clearFormBeneficiarios() {
    this.formBeneficiarios.next(null);
    localStorage.removeItem('formBeneficiarios');
  }

  clearFormAumentoInversion() {
    this.formAumentoInversion.next(null);
    localStorage.removeItem('formAumentoInversion');
  }
}