<app-menu-preregistro></app-menu-preregistro>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">

        <div class="col-11 d-flex flex-column justify-content-center align-items-center progresoRegistroCaja aparece" style="--porcentaje: 10">
            <div class="col-11 d-flex justify-content-start align-items-center">
                <p class="text-start m-0 me-1 fs-1 fw-regular">Paso</p>
                <button type="button" class="bg-transparent border-0 d-flex justify-content-center align-items-center" style="height: 2.2rem; width: 2.2rem;" data-bs-toggle="modal" data-bs-target="#modal">
                    <i class="fa-solid fa-circle-info text-primary fs-5 p-1"></i>
                </button>
                <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                        <div class="container">
                            <div class="row d-flex justify-content-center align-items-center">
                                <div class="col-10">
                                    <div class="card">
                                        <div class="card-body p-5">
                                            <div class="cont-btn-close">
                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                  <i class="fa-solid fa-x"></i>
                                                </button>
                                              </div>
                                            <h4 class="card-title text-center text-primary">Pasos</h4>
                                            <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-11 d-flex justify-content-start align-items-center cajaTexto">
                <span class="me-2">1</span>
                <span> de 7</span>
            </div>
            <div class="col-11" style="--porcentaje: 10">
                <svg class="w-75 m-0 progresoRegistro">
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="70" />
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="70" />
                </svg>
            </div>
            
        </div>

        <div class="col-11 mb-5 aparece-dos">
            <form class="d-flex flex-column justify-content-center align-items-center" id="formDatos" [formGroup]="formDatos" (ngSubmit)="onSubmit()">
                <div class="col-11 aparece">
                  <h1>{{ title }}</h1>
                </div>
                <div class="col-11 aparece-dos">
                    <p>Ingresa los datos cómo se muestra en tu identificación oficial:</p>
                    <p class="me-4 mt-1 mb-4 fs-6 text-primary fst-italic">* Campos obligatorios</p>
                    <div>

                        <label class="form-label" for="nombres">*Nombre(s):</label>
                        <div class="d-flex" style="position: relative;">
                            <input class="form-control mb-0 me-2" type="text" id="nombres" formControlName="nombres" required>
                            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalDos">
                                <i class="fa-solid fa-circle-info text-primary p-1"></i>
                            </button>
                            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalDos" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                    <div class="container">
                                        <div class="row d-flex justify-content-center align-items-center">
                                            <div class="col-10">
                                                <div class="card">
                                                    <div class="card-body p-5">
                                                        <div class="cont-btn-close">
                                                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                              <i class="fa-solid fa-x"></i>
                                                            </button>
                                                          </div>
                                                        <h4 class="card-title text-center text-primary">Nombres</h4>
                                                        <p class="card-text text-center">Info adicional</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="alert alert-danger" [hidden]="nombres?.['valid'] || nombres?.['pristine']">
                            <span *ngIf="nombres?.errors?.['required']">Este campo no puede quedar vacío</span>
                            <span *ngIf="nombres?.errors?.['pattern']">Solo puede contener letras</span>
                        </div>
    
            

                
                        <label class="form-label" for="apellidos">*Apellido(s):</label>
                        <div class="d-flex" style="position: relative;">
                            <input class="form-control mb-0 me-2" type="text" id="apellidos" formControlName="apellidos" required>
                            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modal">
                                <i class="fa-solid fa-circle-info text-primary p-1"></i>
                            </button>
                            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                    <div class="container">
                                        <div class="row d-flex justify-content-center align-items-center">
                                            <div class="col-10">
                                                <div class="card">
                                                    <div class="card-body p-5">
                                                        <div class="cont-btn-close">
                                                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                            <i class="fa-solid fa-x"></i>
                                                            </button>
                                                        </div>
                                                        <h4 class="card-title text-center text-primary">Pasos</h4>
                                                        <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-danger" [hidden]="apellidos?.['valid'] || apellidos?.['pristine']">
                            <span *ngIf="apellidos?.errors?.['required']">Este campo no puede quedar vacío</span>
                            <span *ngIf="apellidos?.errors?.['pattern']">Solo puede contener letras</span>
                        </div>

                        <label class="form-label" for="rfc">*RFC:</label>
                        <div class="d-flex" style="position: relative;">
                            <input class="form-control mb-0 me-2" type="text" id="rfc" formControlName="rfc" required>
                            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modal">
                                <i class="fa-solid fa-circle-info text-primary p-1"></i>
                            </button>
                            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                    <div class="container">
                                        <div class="row d-flex justify-content-center align-items-center">
                                            <div class="col-10">
                                                <div class="card">
                                                    <div class="card-body p-5">
                                                        <div class="cont-btn-close">
                                                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                            <i class="fa-solid fa-x"></i>
                                                            </button>
                                                        </div>
                                                        <h4 class="card-title text-center text-primary">Pasos</h4>
                                                        <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-danger" [hidden]="rfc?.['valid'] || rfc?.['pristine']">
                            <span *ngIf="rfc?.errors?.['required']">Este campo no puede quedar vacío</span>
                            <span *ngIf="rfc?.errors?.['pattern']">Solo puede contener letras</span>
                        </div>
                    
                        <label class="form-label" for="genero">*Género:</label>
                        <div class="d-flex" style="position: relative;">
                            <select class="fs-6 me-2 form-select text-primary custom-select" formControlName="genero">
                                <option disabled selected value="">--Selecciona una opción--</option>
                                <option value="masculino">Masculino</option>
                                <option value="femenino">Femenino</option>
                                <option value="sinespecificar">Prefiero no especificarlo</option>
                            </select>
                            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modal">
                                <i class="fa-solid fa-circle-info text-primary p-1"></i>
                            </button>
                            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                    <div class="container">
                                        <div class="row d-flex justify-content-center align-items-center">
                                            <div class="col-10">
                                                <div class="card">
                                                    <div class="card-body p-5">
                                                        <div class="cont-btn-close">
                                                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                            <i class="fa-solid fa-x"></i>
                                                            </button>
                                                        </div>
                                                        <h4 class="card-title text-center text-primary">Pasos</h4>
                                                        <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-danger" [hidden]="genero?.['valid'] || genero?.['untouched']">
                            <span *ngIf="genero?.errors?.['required']">Este campo no puede quedar vacío</span>
                        </div>
                    
                        <label class="form-label" for="fechanac">*Fecha de nacimiento:</label>
                        <div class="date-input-container d-flex" style="position: relative;">
                            <div class="w-100 date-input-container me-2" style="position: relative;">
                                <input type="date" class="form-control mb-0" id="fechanac" style="padding-right: 30px; background-color: #ffffff00;" formControlName="fechanac" required>
                                <i class="fs-4 text-primary fas fa-calendar-alt" style="position: absolute; right: 10px; bottom: 12px; cursor: pointer;"></i>
                            </div>
                            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modal">
                                <i class="fa-solid fa-circle-info text-primary p-1"></i>
                            </button>
                            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                    <div class="container">
                                        <div class="row d-flex justify-content-center align-items-center">
                                            <div class="col-10">
            
                                                <div class="card">
                                                    <div class="card-body p-5">
                                                        <div class="d-flex justify-content-end align-items-end">
                                                            <button type="button" class="btn-close text-end" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <h4 class="card-title text-center text-primary">Pasos</h4>
                                                        <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-danger" [hidden]="fechanac?.['valid'] || fechanac?.['untouched']">
                            <span *ngIf="fechanac?.errors?.['required']">Este campo no puede quedar vacío</span>
                            <span *ngIf="fechanac?.errors?.['menorDeEdad']">Recuerda que debes ser mayor de edad</span>
                        </div>

                        <label class="form-label" for="nacionalidad">*Nacionalidad:</label>
                        <div class="date-input-container d-flex" style="position: relative;">
                            <select class="fs-6 me-2 form-select text-primary custom-select" formControlName="nacionalidad">
                                <option disabled selected value="">--Selecciona una opción--</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Mexico">México</option>
                                <option value="Chile">Chile</option>
                                <option value="USA">Estados Unidos</option>
                                <option value="Canada">Canadá</option>
                            </select>
                            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modal">
                                <i class="fa-solid fa-circle-info text-primary p-1"></i>
                            </button>
                            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                    <div class="container">
                                        <div class="row d-flex justify-content-center align-items-center">
                                            <div class="col-10">
            
                                                <div class="card">
                                                    <div class="card-body p-5">
                                                        <div class="d-flex justify-content-end align-items-end">
                                                            <button type="button" class="btn-close text-end" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <h4 class="card-title text-center text-primary">Pasos</h4>
                                                        <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-danger" [hidden]="nacionalidad?.['valid'] || nacionalidad?.['untouched']">
                            <span *ngIf="nacionalidad?.errors?.['required']">Este campo no puede quedar vacío</span>
                        </div>

                </div>
            </div>
    
            <div class="col-12 contenedor-botones aparece-tres">
              <div class="col-11">
                <button class="btn btn-primary w-100" type="submit" [disabled]="formDatos.invalid">Continuar</button>
              </div>
            </div>
              
        </form>
          
        <div class="w-100 my-5 d-flex flex-column justify-content-center align-items-center aparece-tres">
            <a class="col-12 text-center my-5" routerLink="/registro03" routerLinkActive="active-link">"Avanzar con la prueba"</a>
        </div>

        </div>
    </div>
</div>
