import { Component } from '@angular/core';

@Component({
  selector: 'app-privacidad',
  templateUrl: './privacidad.component.html',
  styles: [
  ]
})
export class PrivacidadComponent {

  title = 'Política de Privacidad de Arcsa Capital';
}
