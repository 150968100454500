<app-menu-preregistro></app-menu-preregistro>

<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">
        <div class="col-11 mb-5 progresoRegistroCaja aparece-dos">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="col-11 aparece">
                    <h1>{{ title }}</h1>
                </div>
                <div class="col-11 aparece-dos">
                    <p class="mb-3">Asegúrate que la información que proporcionaste sea correcta.</p>
                    <div class="mb-3" *ngFor="let beneficiario of beneficiarios; let i = index">
                        <div class="review-section">
                            <h4 class="fs-3">Nombre(s):</h4>
                            <p>{{ beneficiario.nombres }}</p>
                            <h4 class="fs-3">Apellido(s):</h4>
                            <p>{{ beneficiario.apellidos }}</p>
                            <h4 class="fs-3">Parentesco:</h4>
                            <p>{{ beneficiario.parentesco }}</p>
                            <h4 class="fs-3">Fecha de Nacimiento:</h4>
                            <p>{{ beneficiario.fechanac }}</p>
                            <h4 class="fs-3">Porcentaje:</h4>
                            <p>{{ beneficiario.porcentaje }}</p>
                        </div>
                        <hr *ngIf="i < beneficiarios.length - 1">
                    </div>
                </div>

                <div *ngIf="errorMessage" class="w-100 alert alert-danger">
                    {{ errorMessage }}
                </div>
                <div *ngIf="successMessage" class="w-100 alert alert-success">
                    {{ successMessage }}
                </div>
                
                <div class="col-12 contenedor-botones aparece-tres">
                    <div class="col-12">
                        <button class="w-100 mb-3 btn btn-primary" (click)="onSubmit()">Confirmar</button>
                        <button class="w-100 btn btn-secondary" (click)="onEdit()">Corregir Datos</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
