<app-user-status></app-user-status>

<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">
        

        <div class="col-11 mb-3 aparece">
            <h1>{{ title }}</h1>
        </div>
        
        <div class="col-11 mb-5 aparece-dos">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="col-11">

                    <h2 class="mb-4">Por favor sigue las instrucciones:</h2>

                    <ol>
                        <li>Lee cuidadosamente y corrobora tus datos</li>
                        <li>Descarga tu contrato</li>
                        <li>Fírmalo</li>
                        <li>Adjunta tu contrato firmado</li>
                    </ol>
                </div>
            </div>
        </div>

        

        <div class="col-11 contenedor-botones mt-5 pt-0 aparece-tres"> <!-- [formGroup]="formContrato" (ngSubmit)="onSubmit()" -->
            <div class="col-11 d-flex flex-column">

                <div class="mx-2 px-3 input-custom btn btn-info d-flex justify-content-center align-items-center border border-secondary border-2" *ngIf="pdfUrl">
                    <a class="fs-3 fw-semibold text-decoration-none"  href="{{ pdfUrl }}" download="Contrato_ArcsaCapital.pdf">Descargar tu contrato</a>
                    <div class="ms-5">
                        <i class="fa-solid fa-file-pdf text-secondary pt-2" style="font-size: 2.6rem; overflow: hidden;"></i>
                    </div>
                </div>
                
                <div class="px-3 input-custom btn btn-primary btn btn-info d-flex justify-content-center align-items-center border border-secondary border-2" style="cursor: pointer;">
                    <a (click)="fileInput.click()" class="fs-3 py-2 fw-semibold text-decoration-none">Subir comprobante</a>
                    <input class="py-3" type="file" #fileInput (change)="onFileSelect($event)" hidden>
                </div>

                <div class="col-12 m-auto my-5" *ngIf="archivoSeleccionado">
                    <div class="alert alert-light">
                        Archivo seleccionado: {{ archivoSeleccionado.name }}
                    </div>
                </div>
                
                <div *ngIf="errorMessage" class="alert alert-danger">
                    {{ errorMessage }}
                </div>
                <div *ngIf="successMessage" class="alert alert-success">
                    {{ successMessage }}
                </div>

                <div class="w-100">
                    <button class="w-100 btn btn-primary" type="submit" (click)="enviarContrato()" *ngIf="archivoSeleccionado">Enviar</button>
                </div>

            </div>
        </div>

    </div>
</div>