<app-user-status></app-user-status>

<app-boton-agregar></app-boton-agregar>

<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">
        <div class="col-11 mb-3 aparece">
            <h1>{{ title }}</h1>
        </div>
        <div class="col-11 mb-3 aparece-dos">
            <div class="contenedor-tarjetas">

                <!-- Tabla Contratos Activos -->
                <ng-container *ngIf="inversionesActivas.length > 0">
                    <h2 class="mb-3 mt-5">Contratos activos</h2>
                    <div *ngFor="let inversion of inversionesActivas" class="tarjetas my-2">
                        <a (click)="seleccionarCuenta(inversion.nombreCuenta)">
                            <table class="tarjeta tarjeta-activa">
                                <tr>
                                    <th colspan="2" class="title">{{ inversion.nombreCuenta }}</th>
                                    <th rowspan="6" class="icon-angle">
                                        <button class="boton-angle">
                                            <i class="fa-solid fa-angle-right"></i>
                                        </button>
                                    </th>
                                </tr>
                                <tr>
                                    <td><p>Estatus:</p></td>
                                    <td class="td-info">
                                        <p>{{ inversion.estatus }}</p>
                                        <button type="button" data-bs-toggle="modal" data-bs-target="#modal">
                                            <i class="fa-solid fa-circle-info"></i>
                                        </button>
                                        <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                                <div class="container">
                                                    <div class="row d-flex justify-content-center align-items-center">
                                                        <div class="col-10">
                                                            <div class="card">
                                                                <div class="card-body p-5">
                                                                    <div class="cont-btn-close">
                                                                        <button type="button" data-bs-dismiss="modal" aria-label="Close">
                                                                            <i class="fa-solid fa-x" style="overflow: hidden; cursor: pointer;"></i>
                                                                        </button>
                                                                    </div>
                                                                    <h4 class="card-title text-center text-primary">Progreso</h4>
                                                                    <p class="card-text text-center">Info sobre el progreso</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p>Monto:</p></td>
                                    <td><p>{{ inversion.monto_inversion | currency }}</p></td>
                                </tr>
                                <tr>
                                    <td><p>Apertura:</p></td>
                                    <td><p>{{ inversion.fechaRegistro | date }}</p></td>
                                </tr>
                                <tr>
                                    <td><p>Plazo:</p></td>
                                    <td><p>{{ inversion.plazo_anios }} años</p></td>
                                </tr>
                                <tr>
                                    <td><p>Rendimientos:</p></td>
                                    <td><p>{{ inversion.porcentaje }}%</p></td>
                                </tr>
                            </table>
                        </a>
                    </div>
                </ng-container>
    
                <!-- Tabla Contratos Inactivos -->
                <ng-container *ngIf="inversionesInactivas.length > 0">
                    <h2 class="mb-3 mt-5">Contratos pendientes</h2>
                    <div *ngFor="let inversion of inversionesInactivas" class="tarjetas my-2">
                        <table class="tarjeta tarjeta-pendiente">
                            <tr>
                                <th colspan="2" class="title">{{ inversion.nombreCuenta }}</th>
                                <th rowspan="6" class="icon-angle">
                                    <button class="boton-angle">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </button>
                                </th>
                            </tr>
                            <tr>
                                <td><p>Estatus:</p></td>
                                <td class="td-info">
                                    <p>{{ inversion.estatus }}</p>
                                    <button type="button" data-bs-toggle="modal" data-bs-target="#modal">
                                        <i class="fa-solid fa-circle-info"></i>
                                    </button>
                                    <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                            <div class="container">
                                                <div class="row d-flex justify-content-center align-items-center">
                                                    <div class="col-10">
                                                        <div class="card">
                                                            <div class="card-body p-5">
                                                                <div class="cont-btn-close">
                                                                    <button type="button" data-bs-dismiss="modal" aria-label="Close">
                                                                        <i class="fa-solid fa-x" style="overflow: hidden; cursor: pointer;"></i>
                                                                    </button>
                                                                </div>
                                                                <h4 class="card-title text-center text-primary">Progreso</h4>
                                                                <p class="card-text text-center">Info sobre el progreso</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><p>Monto:</p></td>
                                <td><p>{{ inversion.monto_inversion | currency }}</p></td>
                            </tr>
                            <tr>
                                <td><p>Apertura:</p></td>
                                <td><p>{{ inversion.fechaRegistro | date }}</p></td>
                            </tr>
                            <tr>
                                <td><p>Plazo:</p></td>
                                <td><p>{{ inversion.plazo_anios }} años</p></td>
                            </tr>
                            <tr>
                                <td><p>Rendimientos:</p></td>
                                <td><p>{{ inversion.porcentaje }}%</p></td>
                            </tr>
                        </table>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
