<div class="container-fluid contenedor-94 mt-5 pt-5 d-flex flex-column justify-content-center">
  <div class="row mt-5 pb-5 d-flex justify-content-evenly align-items-center">
    <div class="col-11 pb-5">

      <div class="col-md-5 col-8 pb-5 mb-5 m-auto aparece">
        <img class="img-fluid" src="../../../assets/logoAC_completo_color.png">
        <h5 class="text-center">Fondo de inversión privado</h5>
      </div>
      
      <form class="d-flex flex-column justify-content-center align-items-center" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      
        <div class="col-11 aparece-dos">
            <h1>{{ title }}</h1>
        </div>
      
        <div class="col-11 mt-3 mb-2 aparece-dos">
          <label class="form-label" for="email">Email:</label>
          <input class="form-control" type="email" id="email" formControlName="email" required>
          <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
            Email is required
          </div>
          
          <label class="form-label" for="contrasena">Contraseña:</label>
          <div class="d-flex" style="position: relative;">
            <input class="form-control" type="password" [type]="isPasswordVisible ? 'text' : 'password'" id="contrasena" formControlName="contrasena" required>
              <button type="button" class="mx-2 fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" (click)="togglePasswordVisibility()">
                <i [ngClass]="isPasswordVisible ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'" class="text-primary p-1"></i>
              </button>
              <div *ngIf="submitted && f['contrasena'].errors" class="invalid-feedback">
                Contraseña is required
              </div>
          </div>
        </div>

        <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
      
        <div class="col-11 contenedor-botones aparece-tres">
          <button class="w-100 btn btn-primary" type="submit">Iniciar Sesión</button>
          <a class="d-flex justify-content-center mt-3 link-underline link-underline-opacity-0" routerLink="/restablecer" routerLinkActive="active-link">¿Olvidaste tu contraseña?</a>
          <a class="d-flex justify-content-center mt-3 link-underline link-underline-opacity-0" routerLink="/preregistro02" routerLinkActive="active-link">Crear cuenta</a>
        </div>
        
      </form>
    </div>

  </div>
</div>