import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-preregistro01',
  templateUrl: './preregistro01.component.html',
  styleUrls: []
})

export class Preregistro01Component {

  constructor( private _location: Location, private router: Router ){ }

  regresar(){
    this._location.back();
  }

}