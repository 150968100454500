import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { TempDataService } from '../../services/temp-data.service';

@Injectable({
  providedIn: 'root'
})
export class InversionesService {

  private apiUrl = 'https://login.arcsacapital.com/inversiones_get';

  constructor(
    private http: HttpClient,
    private tempDataService: TempDataService
    ) { }

    obtenerInversiones(): Observable<any> {
      return this.tempDataService.getFormDatosPersonales().pipe(
        switchMap(datos => {
          const url = datos && datos.nombreCuenta ? `${this.apiUrl}?nombreCuenta=${datos.nombreCuenta}` : this.apiUrl;
          return this.http.get(url);
        })
      );
    }
}
