<app-user-status [showBackButton]="true"></app-user-status>

<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">
        
        <div class="col-11">
            <form class="d-flex flex-column justify-content-center align-items-center" [formGroup]="formSimulador" (ngSubmit)="capturarRendimientos()">

                <div class="col-11 aparece">
                    <h1>{{ title }}</h1>
                </div>

                <div class="col-11 mt-5 aparece-dos">

                    <div class="d-flex justify-content-center align-items-start">
                        <p class="fs-3 fw-medium p-noTransform" style="width: fit-content !important; margin: 0 !important;">Capital actual</p>
                    </div>
                    <div>
                        <h2 class="texto-monto">{{ capitalInicial | number:'1.0-0' }} USD</h2>
                    </div>

                    <div class="mt-4 d-flex justify-content-center align-items-start" style="transform: translate(0) !important;">
                        <p class="fs-3 fw-medium p-noTransform" style="width: fit-content !important; margin: 0 !important;">Aumento de capital</p>
                    </div>
                    <div>
                        <input class="form-control mb-0 fs-1 py-3 text-center text-secondary fw-bold" placeholder="15,000 USD" type="text" formControlName="aumentoInversion" (focus)="onMontoFocus($event)" (blur)="onMontoBlur($event)" required>
                        <div class="alert alert-danger" [hidden]="aumentoInversion?.['valid'] || aumentoInversion?.['pristine']">
                            <span *ngIf="aumentoInversion?.errors?.['min'] || aumentoInversion?.errors?.['NG0100']">El monto mínimo es de $15,000 USD</span>
                        </div>
                        <div class="alert alert-success" [hidden]="aumentoInversion?.['invalid'] || aumentoInversion?.['touched']">
                            <span>El monto mínimo es correcto</span>
                        </div>
                    </div>

                    <div class="mt-4 d-flex justify-content-center align-items-start" style="transform: translate(0, 1.4rem) !important;">
                        <p class="fs-3 text-secondary fw-medium p-noTransform" style="width: fit-content !important; margin: 0 !important;">Capital final</p>
                    </div>
                    <div>
                        <h2 class="text-secondary texto-monto">{{ sumaCapital | number:'1.0-0' }} USD</h2>
                    </div>

                </div>

                <div class="procesoCargandoCaja my-5" *ngIf="isLoading || escribiendo">
                    <svg class="procesoCargando">
                        <circle r="14" cx="50%" cy="50%" pathLength="100" />
                        <circle r="14" cx="50%" cy="50%" pathLength="100" />
                    </svg>
                    <span>Simulando tu inversión</span>
                </div>

                <div class="container-fluid" *ngIf="!isLoading">
                    <div class="row" *ngIf="rendimientoPorcentaje">
                        <div class="col-11 m-auto mt-5 pt-5 aparece">
                            <h3 class="mb-0 pb-0 fw-medium text-center">Rendimientos</h3>
                            <p class="fs-3 mb-4 mt-0 pt-0 text-center">Conoce el nuevo rendimiento que obtendrás con tu aumento de capital.
                            </p>
                            <div class="mt-5 d-flex flex-wrap justify-content-center align-items-center">
                                <div class="w-50">
                                    <h2 class="text-center text-dark fs-2">Rendimiento mensual:</h2>
                                    <p class="text-center text-primary fs-2 fw-semibold p-noTransform rendimientos">{{ calculoMensual | number:'1.2-2' }}</p>
                                </div>
                                <div class="w-50">
                                    <h2 class="text-center text-dark fs-2">Rendimiento anual:</h2>
                                    <p class="text-center text-primary fs-2 fw-semibold p-noTransform rendimientos">{{ calculoAnual | number:'1.2-2' }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="mensajeRespuesta" class="alert alert-info">
                    {{ mensajeRespuesta }}
                </div>

                <div class="col-12 contenedor-botones aparece-tres">
                    <div class="col-11">
                        <button class="btn btn-primary w-100" type="submit" [disabled]="formSimulador.invalid || isLoading">Continuar</button>
                    </div>
                </div>

            </form>
            
            <!-- <div class="w-100 my-5 d-flex flex-column justify-content-center align-items-center aparece-tres">
                <a class="col-12 text-center my-5" (click)="continuar()">Avanzar con la prueba</a>
            </div> -->
            
        </div>
    </div>
</div>
