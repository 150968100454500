import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { TempDataService } from '../../../services/temp-data.service';
import { ProgresoUsuarioService } from '../../../services/progreso-usuario/progreso-usuario.service';

interface Beneficiario {
  nombres: string;
  apellidos: string;
  parentesco: string;
  fechanac: Date;
  porcentaje: number;
}

@Component({
  selector: 'app-registro06',
  templateUrl: './registro06.component.html',
  styleUrls: []
})

export class Registro06Component implements OnInit {

  title = "Beneficiarios";
  formDatos: FormGroup;

  constructor(
    private router: Router,
    private tempDataService: TempDataService,
    private progresoUsuarioService: ProgresoUsuarioService,
  ) {
    this.formDatos = new FormGroup({
      beneficiarios: new FormArray([this.crearBeneficiario()], [this.validadorSumaPorcentajes()])
    });
  }

  ngOnInit(): void {
    this.progresoUsuarioService.checkUserProgressAndNavigate();
  }

  crearBeneficiario(): FormGroup {
    return new FormGroup({
      nombres: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ\s]+$/)]),
      apellidos: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ\s]+$/)]),
      parentesco: new FormControl('', Validators.required),
      fechanac: new FormControl('', [Validators.required, this.mayorDeEdadValidator()]),
      porcentaje: new FormControl('', [Validators.required, Validators.min(1), Validators.max(100)])
    });
  }

  agregarBeneficiario() {
    const beneficiariosArray = this.formDatos.get('beneficiarios') as FormArray;
    beneficiariosArray.push(this.crearBeneficiario());
  }

  removerBeneficiario(index: number) {
    const beneficiariosArray = this.formDatos.get('beneficiarios') as FormArray;
    beneficiariosArray.removeAt(index);
  }

  get beneficiarios(): FormArray {
    return this.formDatos.get('beneficiarios') as FormArray;
  }

  mayorDeEdadValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fechaNacimiento = new Date(control.value);
      const hoy = new Date();
      const fechaLimite = new Date(hoy.getFullYear() - 18, hoy.getMonth(), hoy.getDate());
      return fechaNacimiento <= fechaLimite ? null : { 'menorDeEdad': { value: control.value } };
    };
  }

  validadorSumaPorcentajes(): ValidatorFn {
    return (formArray: AbstractControl): ValidationErrors | null => {
      let total = 0;
      (formArray as FormArray).controls.forEach(control => {
        const porcentaje = control.get('porcentaje')?.value || 0;
        total += +porcentaje;
      });
      return total === 100 ? null : { 'sumaPorcentajesIncorrecta': { total } };
    };
  }

  get validadorSumaPorcentajesSuccess(): boolean {
    let total = 0;
    if (this.beneficiarios && this.beneficiarios.controls) {
      this.beneficiarios.controls.forEach(control => {
        const porcentaje = control.get('porcentaje')?.value;
        if (porcentaje !== null && porcentaje !== undefined) {
          total += +porcentaje;
        }
      });
    }
    return total === 100;
  }

  getPorcentajeTotal(): number {
    let total = 0;
    if (this.beneficiarios && this.beneficiarios.controls) {
      this.beneficiarios.controls.forEach(control => {
        const porcentaje = control.get('porcentaje')?.value;
        if (porcentaje !== null && porcentaje !== undefined) {
          total += +porcentaje;
        }
      });
    }
    return total;
  }

  onSubmit() {
    if (!this.formDatos.valid) {
      console.error('El formulario no es válido, por favor revise los campos de entrada.');
      return;
    }

    this.tempDataService.setFormBeneficiarios(this.formDatos.value);
    this.router.navigate(['/registro07']).then(success => {
      console.log('Navegación exitosa:', success);
    }).catch(error => {
      console.error('Error de navegación:', error);
    });
  }
}