import { Component } from '@angular/core';

@Component({
  selector: 'app-perfil-aumento04',
  templateUrl: './perfil-aumento04.component.html',
  styles: [
  ]
})
export class PerfilAumento04Component {

}
