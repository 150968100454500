import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/logged/auth.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-comprobante02',
  templateUrl: './comprobante02.component.html',
  styleUrls: []
})
export class Comprobante02Component implements OnInit {

  title = 'Sube tu comprobante de inversión';
  form: FormGroup;
  archivoSeleccionado?: File;
  errorMessage: string | null = null;
  successMessage: string | null = null;
  nombreCuentaTemp: string | null = null;
  private MAX_SIZE: number = 2 * 1024 * 1024;

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router
  ) {
    this.form = new FormGroup({
      archivo: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
    this.fetchAccountName();
  }

  fetchAccountName() {
    this.http.get<any[]>('https://login.arcsacapital.com/cuentas_get', {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.authService.getToken()}`
      })
    }).pipe(
      tap(data => {
        if (data && data.length > 0 && data[0].hasOwnProperty('nombreCuenta')) {
          this.nombreCuentaTemp = data[0].nombreCuenta;
          console.log('Nombre de cuenta obtenido:', this.nombreCuentaTemp);
        } else {
          throw new Error('No se encontró el campo nombreCuenta o el array está vacío.');
        }
      }),
      catchError(error => {
        console.error('Error al realizar la solicitud GET:', error);
        return of(null);
      }),
      finalize(() => {})
    ).subscribe();
  }

  onFileSelect(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) {
      this.errorMessage = "No file selected";
      return;
    }

    if (file.size > this.MAX_SIZE) {
      this.errorMessage = 'El archivo supera el límite de 2MB';
      return;
    }

    this.archivoSeleccionado = file;
    this.errorMessage = null;
    this.successMessage = null;
    this.cdr.markForCheck();
  }

  onSubmit() {
    if (!this.archivoSeleccionado) {
      this.errorMessage = 'Por favor, seleccione un archivo.';
      this.cdr.markForCheck();
      return;
    }

    if (!this.nombreCuentaTemp) {
      this.errorMessage = 'No se pudo obtener el nombre de la cuenta.';
      this.cdr.markForCheck();
      return;
    }

    const formData = new FormData();
    formData.append('comprobante', this.archivoSeleccionado);
    formData.append('nombreCuenta', this.nombreCuentaTemp);

    this.http.post('https://login.arcsacapital.com/comprobante', formData, { observe: 'response' })
      .subscribe({
        next: (response) => {
          if (response.status === 201) {
            this.successMessage = 'Archivo subido con éxito';
            setTimeout(() => {
              this.router.navigate(['/comprobante03']);
            }, 2000);
          } else {
            this.errorMessage = 'Respuesta no reconocida del servidor';
          }
          this.cdr.markForCheck();
        },
        error: (error) => {
          this.successMessage = null;
          this.errorMessage = error.error.message || 'Error al enviar los datos, intente de nuevo más tarde.';
          this.cdr.markForCheck();
        }
      });
  }
}
