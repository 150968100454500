<div class="container-fluid d-flex contenedor">
    <div class="row d-flex flex-column justify-content-between align-items-center">


        <div class="d-flex flex-column justify-content-evenly align-items-center contenedor-uno aparece">
            <img src="../../../assets/logoAC_color.png" class="col-3" style="max-width: 8rem;">
            <div class="d-flex justify-content-center align-items-center contenedor-icono">
                <img src="../../../assets/onboarding03.svg" class="icono-onboarding">
                <div class="punto punto-uno"></div>
                <div class="punto punto-dos"></div>
                <div class="punto punto-tres"></div>
                <div class="punto punto-cuatro"></div>
                <div class="punto punto-cinco"></div>
            </div>
        </div>

        <div class="bg-primary d-flex flex-column justify-content-evenly align-items-center contenedor-dos aparece-dos pb-5">
            <div class="col-10 d-flex flex-column justify-content-evenly align-items-center pb-5">
                <span class="text-center text-white numeros">03</span>
                <span class="text-center mt-2 mb-4 text-info titulo-onboarding">Control total en tus manos</span>
                <p class="text-center text-white">Administra tu perfil y tus contratos desde la aplicación móvil, sin comisiones por apertura o manejo de cuenta.</p>
                <div class="w-100 pb-5 mt-4 d-flex justify-content-between align-items-center contenedor-flecha aparece-tres">
                    <button class="mt-4 bg-secondary text-pr w-100 btn btn-primary d-flex justify-content-center align-items-center"  (click)="navegar()">Terminar<i class="fa-solid fa-arrow-right ms-3"></i></button>
                </div>
            </div>
        </div>
        
        
    </div>
</div>


  