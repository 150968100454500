<app-user-status></app-user-status>

<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">
        
        <div class="col-11 d-flex flex-column justify-content-center align-items-center progresoRegistroCaja aparece" style="--porcentaje: 10">
            <div class="col-11 d-flex justify-content-start align-items-center">
                <p class="text-start m-0 me-1 fs-1 fw-regular">Paso</p>
                <button type="button" class="bg-transparent border-0 d-flex justify-content-center align-items-center" style="height: 2.2rem; width: 2.2rem;" data-bs-toggle="modal" data-bs-target="#modal">
                    <i class="fa-solid fa-circle-info text-primary fs-5 p-1"></i>
                </button>
                <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                        <div class="container">
                            <div class="row d-flex justify-content-center align-items-center">
                                <div class="col-10">
                                    <div class="card">
                                        <div class="card-body p-5">
                                            <div class="cont-btn-close">
                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                  <i class="fa-solid fa-x"></i>
                                                </button>
                                              </div>
                                            <h4 class="card-title text-center text-primary">Pasos</h4>
                                            <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-11 d-flex justify-content-start align-items-center cajaTexto">
                <span class="me-2">2</span>
                <span> de 5</span>
            </div>
            <div class="col-11" style="--porcentaje: 20">
                <svg class="w-75 m-0 progresoRegistro">
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="50" />
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="50" />
                </svg>
            </div>
            
        </div>

        <div class="col-11">
            <form class="d-flex flex-column justify-content-center align-items-center" [formGroup]="formSimulador" (ngSubmit)="capturarRendimientos()">

                <div class="col-11 aparece">
                    <h1>{{ title }}</h1>
                </div>

                <div class="col-11 mt-5 aparece-dos">

                    <!-- <p class="me-4 mb-4 fs-6 text-primary fst-italic">* Campos obligatorios</p> -->

                    <div class="d-flex justify-content-start align-items-start" style="transform: translate(0, 1.4rem) !important;">
                        <p class="fs-3 fw-medium p-noTransform" style="width: fit-content !important; margin: 0 !important;">Monto de inversión</p>
                        <button type="button" class="ms-1 fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modal">
                            <i class="fa-solid fa-circle-info text-primary p-1"></i>
                        </button>
                    </div>
                    <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                            <div class="container">
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="col-10">
                                        <div class="card">
                                            <div class="card-body p-5" style="overflow: hidden;">
                                                <div class="cont-btn-close">
                                                    <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                      <i class="fa-solid fa-x"></i>
                                                    </button>
                                                  </div>
                                                <h4 class="card-title text-center text-primary">Info</h4>
                                                <p class="card-text text-center">Información detallada y relevante</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label class="form-label" for="monto_inversion">Monto de inversión:</label>
                        <input class="form-control mb-0 fs-1 py-3 text-center text-secondary fw-bold" placeholder="15,000.00 USD" type="text" formControlName="monto_inversion" (focus)="onMontoFocus($event)" (blur)="onMontoBlur($event)" required>
                        <p class="fs-6 text-terciary fst-italic" style="transform: translateY(0) !important;">Este campo es obligatorio.</p>
                        <div class="alert alert-danger" [hidden]="monto_inversion?.['valid'] || monto_inversion?.['pristine']">
                            <span *ngIf="monto_inversion?.errors?.['min'] || monto_inversion?.errors?.['NG0100']">El monto mínimo es de $15,000 USD</span>
                        </div>
                        <div class="alert alert-success" [hidden]="monto_inversion?.['invalid'] || monto_inversion?.['touched']">
                            <span>El monto mínimo es correcto</span>
                        </div>
                    </div>
                </div>

                <div class="col-11 mt-3 aparece-dos">
                    <p class="fs-3 fw-medium">Selecciona el plazo de inversión de tu cuenta.</p>
                    <div>
                        <label class="form-label" for="plazo_anios">Años:</label>
                        <select class="form-select text-primary custom-select" formControlName="plazo_anios">
                            <option value="1" selected>1 año</option>
                            <option value="2">2 años</option>
                            <option value="3">3 años</option>
                            <option value="4">4 años</option>
                            <option value="5">5 años</option>
                        </select>
                        <div class="w-100 mt-3 text-center">
                            <span class="fs-1 fw-bold text-primary" style="width: 100% !important;">{{ rendimientoPorcentaje }}%</span>
                            <span class="fs-4" style="width: 100% !important;"> retorno anual</span>
                        </div>
                    </div>
                </div>
                
                <div class="procesoCargandoCaja my-5" *ngIf="isLoading || escribiendo">
                    <svg class="procesoCargando">
                        <circle r="14" cx="50%" cy="50%" pathLength="100" />
                        <circle r="14" cx="50%" cy="50%" pathLength="100" />
                    </svg>
                    <span>Simulando tu inversión</span>
                </div>

                <div class="container-fluid" *ngIf="!isLoading">
                    <div class="row" *ngIf="rendimientoPorcentaje">
                        <div class="col-11 m-auto mt-5 pt-5 aparece">
                            <h3 class="mb-0 pb-0 fw-medium text-center">Rendimientos</h3>
                            <p class="fs-3 mb-4 mt-0 pt-0 text-center">Conoce el rendimiento que obtendrás con tu inversión inicial.
                            </p>
                            <div class="mt-5 d-flex flex-wrap justify-content-center align-items-center">
                                <div class="w-50">
                                    <h2 class="text-center text-dark fs-2">Rendimiento mensual:</h2>
                                    <p class="text-center text-primary fs-2 fw-semibold p-noTransform rendimientos">{{ calculoMensual | number:'1.2-2' }}</p>
                                </div>
                                <div class="w-50">
                                    <h2 class="text-center text-dark fs-2">Rendimiento anual:</h2>
                                    <p class="text-center text-primary fs-2 fw-semibold p-noTransform rendimientos">{{ calculoAnual | number:'1.2-2' }}</p>
                                </div>
                                <div class="w-100 mt-5">
                                    <h2 class="text-center text-dark fs-1" id="rendimiento">Rendimiento final:</h2>
                                    <p class="text-center text-secondary fs-1 fw-semibold p-noTransform rendimientos">{{ calculoTotal | number:'1.2-2' }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="mensajeRespuesta" class="alert alert-info">
                    {{ mensajeRespuesta }}
                </div>

                <div class="col-12 contenedor-botones aparece-tres">
                    <div class="col-11">
                        <button class="btn btn-primary w-100" type="submit" (click)="capturarRendimientos()" [disabled]="!formSimulador.valid || isLoading">Continuar</button>
                    </div>
                </div>

            </form>
            
            <div class="w-100 my-5 d-flex flex-column justify-content-center align-items-center aparece-tres">
                <a class="col-12 text-center my-5" routerLink="/perfil-nuevo04" routerLinkActive="active-link">"Avanzar con la prueba"</a>
            </div>
            
        </div>
    </div>
</div>

