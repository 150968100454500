<app-user-status [showBackButton]="true"></app-user-status>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">

        <div class="col-11 aparece">
            <h1>{{ title }}</h1>
        </div>

        <div class="col-11 mt-5 aparece-dos">
            <p>Arcsa Capital, LLC y Arcsa Services Protection, LLC son un fondo privado que comercializa activos para nuestros socios comerciales. Arcsa Capital, LLC y Arcsa Services Protection, LLC no está disponible para brindar asesoría de inversiones o servicios similares a la mayoría de los otros inversionistas. Este sitio web es un recurso para audiencias distintas de los inversores, como empleados potenciales, investigadores, estudiantes, contrapartes y participantes de la industria. Arcsa Capital, LLC y Arcsa Services Protection, LLC cree que es útil que dichas personas tengan una fuente precisa de información relevante. Bajo ninguna circunstancia la información presentada en este sitio web debe interpretarse como una oferta de venta o solicitud de cualquier oferta de compra de valores u otras inversiones. Este sitio web no contiene la información que un inversor debería considerar o evaluar para realizar una posible inversión. Los materiales de oferta relacionados con inversiones en entidades administradas por Arcsa Capital, LLC y Arcsa Services Protection, LLC no están disponibles para el público en general.</p>
            <br>
            <p>Para ver este contenido, debe aceptar los siguientes términos, además de y complementar los Términos de uso y la Política de privacidad de Bridgewater:</p>
            <br>
            <p>Confirmo a Arcsa Capital, LLC y Arcsa Services Protection, LLC y acepto que:</p>
            <br>
            <ul>
                <li>Entro a este sitio web solo para obtener información general sobre Arcsa Capital, LLC y Arcsa Services Protection, LLC y no para ningún otro propósito.</li>
                <br>
                <li>Entiendo que las inversiones administradas por Arcsa Capital, LLC y Arcsa Services Protection, LLC no están disponibles para el público en general.</li>
                <br>
                <li>Entiendo que este sitio web no contiene la información que debería considerar para una inversión y que dicha información solo está disponible para un grupo limitado de personas e instituciones que cumplen con criterios específicos.</li>
                <br>
                <li>Entiendo que este sitio web no ha sido revisado ni aprobado por, presentado o proporcionado de otra manera a ninguna autoridad gubernamental o similar, y está destinado solo a proporcionar información limitada a los miembros del público que tienen un interés legítimo en esa información por razones no relacionado con la realización de inversiones.</li>
                <br>
                <li>Entiendo que cuando Arcsa Capital, LLC y Arcsa Services Protection, LLC ponen a disposición información de terceros, Bridgewater generalmente no tendrá declaraciones verificadas hechas por terceros, y la presentación de información puede omitir información importante.</li>
                <br>
                <li>Entiendo que los materiales de terceros, como entrevistas en vivo, puestos a disposición por Arcsa Capital, LLC y Arcsa Services Protection, LLC generalmente no habrán sido editados por Bridgewater y las declaraciones en esos materiales por personas asociadas con Bridgewater deben entenderse en el contexto conversacional en que se hicieron, lo que puede incluir proporcionar antecedentes históricos.</li>
                <br>
                <li>El contenido constituye la propiedad intelectual de Arcsa Capital o sus licenciantes y que no copiaré, modificaré, refundiré, crearé trabajos derivados, publicaré, publicaré, mostraré, redistribuiré, divulgaré ni pondré a disposición el contenido, en forma directa o indirecta. o en parte, a terceros, o ayudar a otros a hacer lo mismo, o hacer cualquier uso comercial del contenido sin el consentimiento previo por escrito de Arcsa Capital.</li>
                <br>
                <p>Al registrar mi información a continuación y hacer clic en "Acepto", certifico que he leído, comprendido y acepto el Descargo de responsabilidad, los Términos de uso y la Política de privacidad anteriores.</p>
                <br>
            </ul>
        </div>

        <div class="col-12 contenedor-botones aparece-tres">
            <div class="col-11">
                <button class="btn btn-primary w-100" routerLink="/asistencia" routerLinkActive="active-link">Contactar a un Asesor Financiero</button>
            </div>
        </div>

    </div>
</div>