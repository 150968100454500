import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-restablecer',
  templateUrl: './restablecer.component.html',
  styleUrls: ['./restablecer.component.css']
})
export class RestablecerComponent {

  title = "Escribe el email vinculado a tu cuenta";

  constructor (
    private router: Router, 
    private _location: Location
    ){ }

  regresar(){
    this._location.back();
  };
  
  iniciarSesion(){
    this.router.navigate(['/login']);
  };
  
  crearCuenta(){
    this.router.navigate(['/preregistro02']);
  };
  
  temporal(){
    this.router.navigate(['/restablecerVerifica']);
  };

}
