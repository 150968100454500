import { Component, OnInit } from '@angular/core';
import { TempDataService } from '../../../services/temp-data.service';

@Component({
  selector: 'app-banner-cuenta',
  templateUrl: './banner-cuenta.component.html',
  styleUrls: []
})
export class BannerCuentaComponent implements OnInit {
  nombreCuenta: string = '';

  constructor(
    private tempDataService: TempDataService
  ) {}

  ngOnInit(): void {
    const storedNombreCuenta = localStorage.getItem('nombreCuenta');
    if (storedNombreCuenta) {
      this.nombreCuenta = storedNombreCuenta;
      console.log('nombreCuenta recuperado de localStorage:', this.nombreCuenta);
    } else {
      this.tempDataService.getFormDatosPersonales().subscribe(data => {
        if (data && data.nombreCuenta) {
          this.nombreCuenta = data.nombreCuenta;
          localStorage.setItem('nombreCuenta', this.nombreCuenta);
          console.log('nombreCuenta recuperado del servicio y almacenado en localStorage:', this.nombreCuenta);
        } else {
          console.error('No se encontró nombreCuenta');
        }
      });
    }
  }
}
