import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-onboarding02',
  templateUrl: './onboarding02.component.html',
  styleUrls: ['./onboarding02.component.css']
})
export class Onboarding02Component {

  constructor (private router: Router){ }

  navegar(){
    this.router.navigate(['/onboarding03']);
  };


}
