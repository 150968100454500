import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/logged/auth.service';

@Component({
  selector: 'app-boton-agregar',
  templateUrl: './boton-agregar.component.html',
  styleUrls: []
})
export class BotonAgregarComponent implements OnInit {

  constructor(
    private authService: AuthService
    ) {}

  ngOnInit(): void {
  }

}
