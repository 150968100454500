import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-perfil-aumento03',
  templateUrl: './perfil-aumento03.component.html',
  styles: [
  ]
})
export class PerfilAumento03Component {

  title = 'Sube tu comprobante'
  form: FormGroup;
  archivoSeleccionado: string = '';

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef
    ) {
    this.form = new FormGroup({
      archivo: new FormControl(null, [Validators.required])
    });
  }

  onFileSelect(event: Event) {
    const eventTarget = event.target as HTMLInputElement;
    if (eventTarget.files && eventTarget.files.length) {
      const file = eventTarget.files[0];
      this.archivoSeleccionado = file.name; 
      this.form.patchValue({ archivo: file });
      this.cdr.detectChanges();
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const formData = new FormData();
      formData.append('archivo', this.form.get('archivo')!.value);

      this.http.post('endpoint', formData).subscribe({
        next: (response) => console.log('Archivo subido con éxito', response),
        error: (error) => console.error('Error al subir archivo', error)
      });
    }
  }
}