<div class="w-100 mb-3 mt-3 aparece bg-primary">
    <div class="col-11 m-auto">
        <div class="pt-3 pb-5 d-flex justify-content-between align-items-center">
            <h1 class="text-white pb-0">Cuenta: {{ nombreCuenta || 'Cargando...' }}</h1>
            <!-- <button class="text-white" style="margin-left: 1rem; border: none; background-color: transparent;" type="button" data-bs-toggle="modal" data-bs-target="#modal">
                <i class="fa-solid fa-pen-to-square" style="overflow: hidden;"></i>
            </button> -->
            <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                <div class="modal-dialog aparece">
                  <div class="modal-content">
                      <div class="modal-body">
                        <div class="col-12 d-flex justify-content-end">
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="col-12 mb-5">
                            <form class="d-flex flex-column justify-content-center align-items-center" id="formDatos">
                                <div class="col-11">
                                  <h1>Cambia el nombre de tu cuenta</h1>
                                </div>
                                <div class="col-11">
                                    <p>Ingresa un nuevo nombre con el que la identifiques facilmente</p>
                                    <div>
                
                                        <label class="form-label" for="nombres">Nuevo nombre:</label>
                                        <div class="d-flex" style="position: relative;">
                                            <input class="form-control mb-0 me-2" type="text" id="nombres" name="newAccountName" required>
                                            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalDos">
                                                <i class="fa-solid fa-circle-info text-primary p-1"></i>
                                            </button>
                                            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalDos" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                                <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                                    <div class="container">
                                                        <div class="row d-flex justify-content-center align-items-center">
                                                            <div class="col-10">
                                                                <div class="card">
                                                                    <div class="card-body p-5">
                                                                        <div class="cont-btn-close">
                                                                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                                              <i class="fa-solid fa-x"></i>
                                                                            </button>
                                                                          </div>
                                                                        <h4 class="card-title text-center text-primary">Nombres</h4>
                                                                        <p class="card-text text-center">Info adicional</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
            
                                        <!-- <div class="alert alert-danger" [hidden]="nombres?.['valid'] || nombres?.['pristine']">
                                            <span *ngIf="nombres?.errors?.['required']">Este campo no puede quedar vacío</span>
                                            <span *ngIf="nombres?.errors?.['pattern']">Solo puede contener letras</span>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-12 contenedor-botones aparece-tres">
                                    <div class="col-11">
                                      <button class="btn btn-primary w-100" type="submit">Continuar</button><!-- [disabled]="formDatos.invalid" -->
                                    </div>
                                  </div>
                            </form>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </div>
</div>