import { Component } from '@angular/core';
import { AuthService } from '../../../../services/logged/auth.service';

@Component({
  selector: 'app-status01',
  templateUrl: './status01.component.html',
  styles: [
  ]
})
export class Status01Component {

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  logout(): void {
    this.authService.logout();
  }
}
