import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-restablecida',
  templateUrl: './restablecida.component.html',
  styleUrls: ['./restablecida.component.css']
})
export class RestablecidaComponent {

  constructor (private router: Router, private _location: Location){ }

  title = "";

}
