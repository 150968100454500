import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-preregistro02',
  templateUrl: './preregistro02.component.html',
  styleUrls: [],
})
export class Preregistro02Component {

  title = "Antes de comenzar";

  constructor( private _location: Location, private router: Router ){ }

  regresar(){
    this._location.back();
  }
  
  comenzar() {
    this.router.navigate(['/preregistro03'])
  }

}
