<app-user-status [showBackButton]="true"></app-user-status>
  
<div class="container-fluid contenedor-94">
  <div class="row d-flex justify-content-evenly align-items-center">
    <div class="col-11">
        
      <form class="d-flex flex-column justify-content-center align-items-center" id="formContacto" [formGroup]="formContacto" (ngSubmit)="onSubmit()">

        <div class="col-11 aparece">
          <h1>{{ title }}</h1>
        </div>
        
        
        <div class="col-11 aparece-dos">

          <p class="me-4 mt-1 mb-4 fs-6 text-primary fst-italic">* Campos obligatorios</p> 
        
          <p>Elige tu nombre de usuario:</p> 
          <label class="form-label" for="nombre_usuario">*Nombre de usuario:</label>
          <div class="d-flex" style="position: relative;">
            <input class="form-control mb-0 me-2" type="text" id="nombre_usuario" formControlName="nombre_usuario" required>
            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modal">
              <i class="fa-solid fa-circle-info text-primary p-1"></i>
            </button>
            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                <div class="container">
                  <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-10">
                      <div class="card">
                        <div class="card-body p-5">
                          <div class="cont-btn-close">
                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                              <i class="fa-solid fa-x"></i>
                            </button>
                          </div>
                          <h4 class="card-title text-center text-primary">¿Qué es mi "Nombre de Usuario"?</h4>
                          <p class="card-text text-center">Se utiliza cómo un alias o nombre corto para referirnos a ti. <br>Puedes elegir un apodo o el nombre que más te guste.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="alert alert-danger" [hidden]="nombre_usuario?.['valid'] || nombre_usuario?.['pristine']">Este campo no puede estar vacío</div>
          <p class="me-4 fs-6 text-terciary fst-italic" style="transform: translateY(0) !important;">Puedes usar letras y/o números.</p>

         
          <p class="mt-3">Ingresa aquí si tienes un código de invitado:</p>
          <label class="form-label" for="codigo_invitado">Código de invitado:</label>
          <div class="d-flex" style="position: relative;">
            <input class="form-control mb-0 me-2" type="text" id="codigo_invitado" formControlName="codigo_invitado">
            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalDos">
              <i class="fa-solid fa-circle-info text-primary p-1"></i>
            </button>
            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalDos" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                <div class="container">
                  <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-10">
                      <div class="card">
                        <div class="card-body p-5">
                          <div class="cont-btn-close">
                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                              <i class="fa-solid fa-x"></i>
                            </button>
                          </div>
                          <h4 class="card-title text-center text-primary">¿Qué es un "Código de invitado"?</h4>
                          <p class="card-text text-center">Descripción de que significa.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <p class="mt-3">Por tu seguridad tu correo electrónico y teléfono móvil serán asociados a tu cuenta:</p>
          <label class="form-label" for="telefono_movil">*Teléfono móvil:</label>
          <div class="d-flex" style="position: relative;">
            <input class="form-control mb-0 me-2" type="tel" id="telefono_movil" formControlName="telefono_movil" required>
            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalTres">
              <i class="fa-solid fa-circle-info text-primary p-1"></i>
            </button>
            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalTres" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                <div class="container">
                  <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-10">
                      <div class="card">
                        <div class="card-body p-5">
                          <div class="cont-btn-close">
                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                              <i class="fa-solid fa-x"></i>
                            </button>
                          </div>
                          <h4 class="card-title text-center text-primary">Por qué debo ingresar mi teléfono móvil?</h4>
                          <p class="card-text text-center">Descripción.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="alert alert-danger" [hidden]="telefono_movil?.['valid'] || telefono_movil?.['pristine']">
            <span *ngIf="telefono_movil?.errors?.['required']">Este campo no puede estar vacío</span>
            <span *ngIf="telefono_movil?.errors?.['pattern']">El teléfono debe ser a 10 dígitos</span>
          </div>
          <div class="alert alert-success" [hidden]="telefono_movil?.['invalid'] || telefono_movil?.['touched']">El formato de teléfono es correcto</div>
          
          <label class="form-label mt-0" for="email">*Email:</label>
          <div class="d-flex" style="position: relative;">
            <input class="form-control mb-0 me-2" type="email" id="email" formControlName="email" required>
            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalCuatro">
              <i class="fa-solid fa-circle-info text-primary p-1"></i>
            </button>
            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalCuatro" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                <div class="container">
                  <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-10">
                      <div class="card">
                        <div class="card-body p-5">
                          <div class="cont-btn-close">
                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                              <i class="fa-solid fa-x"></i>
                            </button>
                          </div>
                          <h4 class="card-title text-center text-primary">Email</h4>
                          <p class="card-text text-center">Info extra</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="alert alert-danger" [hidden]="email?.['valid'] || email?.['pristine']">El formato de email es incorrecto</div>
          <div class="alert alert-success" [hidden]="email?.['invalid'] || email?.['touched']">El formato de email es correcto</div>
          
          <p>Crea una contraseña segura:</p>
          <label class="form-label" for="contrasena">*Contraseña:</label>
          <div class="d-flex" style="position: relative;">
            <!--
              <input class="form-control mb-0 me-2" type="password" id="contrasena" formControlName="contrasena" required>
            -->
            <input class="form-control mb-0 me-2" [type]="isPasswordVisible ? 'text' : 'password'" id="contrasena" formControlName="contrasena" required>
            <button type="button" class="me-3 fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" (click)="togglePasswordVisibility()">
              <i [ngClass]="isPasswordVisible ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'" class="text-primary p-1"></i>
            </button>
            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalCinco">
              <i class="fa-solid fa-circle-info text-primary p-1"></i>
            </button> 
            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalCinco" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                <div class="container">
                  <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-10">
                      <div class="card">
                        <div class="card-body p-5">
                          <div class="cont-btn-close">
                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                              <i class="fa-solid fa-x"></i>
                            </button>
                          </div>
                          <h4 class="card-title text-center text-primary">Contraseña</h4>
                          <p class="card-text text-center">Los caracteres especiales que puedes utilizar son: &#64;, $, !, %, *, ?, &, /</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="me-4 fs-6 text-terciary fst-italic" style="transform: translateY(0) !important;">La contraseña debe contener al menos una letra mayúscula, una letra minúscula, un número, un caractér especial y una extensión de 6-18 caracteres.</p>
          <div class="alert alert-danger" *ngIf="formContacto.get('contrasena')?.invalid && formContacto.get('contrasena')?.touched">
            Asegúrate de cumplir con los requisitos de contraseña.
          </div>

        </div>

        <div class="col-11 mt-5 aparece-dos">

          <div class="form-check d-flex justtify-content-center align-items-center">
            <input class="form-check-input input_check" type="checkbox" id="acepta_terminos" formControlName="acepta_terminos" required>
            <label class="form-check-label label_check" for="acepta_terminos">Aceptar <a class="link-offset-1 fs-6 link-underline link-underline-opacity-0" routerLink="/terminos" routerLinkActive="active-link">Términos y condiciones</a></label>
          </div>
          
          <div class="mb-3 form-check d-flex align-items-center">
            <input class="form-check-input input_check" type="checkbox" id="acepta_aviso" formControlName="acepta_aviso" required>
            <label class="form-check-label label_check" for="acepta_aviso">Aceptar <a class="link-offset-1 fs-6 link-underline link-underline-opacity-0" routerLink="/privacidad" routerLinkActive="active-link">Aviso de Privacidad</a></label>
          </div> 
          <div class="alert alert-danger w-100" [hidden]="acepta_aviso?.['valid'] && acepta_terminos?.['valid'] || acepta_aviso?.['pristine'] && acepta_terminos?.['pristine']">Selecciona ambas casillas para continuar</div>

        </div>

        <div *ngIf="errorMessage" class="col-11 alert alert-danger">
          {{ errorMessage }}
        </div>
        <div *ngIf="isSubmissionSuccessful" class="col-11 alert alert-success">
          {{ successMessage }}
        </div>

        <div class="col-12 contenedor-botones aparece-tres">
          <div class="col-11">
            <button class="btn btn-primary w-100" type="submit" [disabled]="formContacto.invalid || isSubmissionSuccessful">Continuar</button>
          </div>
        </div>
          
      </form> 
    </div>
  </div>
</div>