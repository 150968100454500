<app-user-status></app-user-status>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">

        <div class="col-11 aparece">
            <h1>{{ title }}</h1>
        </div>
        
        <div class="col-12 aparece">
            <div class="shadow p-4 m-5 mb-4 mt-0 rounded tarjeta-banco">
                <div>
                    <h4 class="text-primary">Chase Bank</h4>
                </div>

                <div class="d-flex justify-content-between align-items-center my-2">
                    <div>
                        <p class="m-0 p-0">Número de cuenta:</p>
                        <p #textoCopiar class="m-0 p-0 text-primary fw-bold">899266172</p>
                    </div>
                    <div>
                        <button (click)="copiarAlPortapapeles(textoCopiar)">
                            <i class="fa-regular fa-copy text-secondary" style="overflow: hidden;"></i>
                        </button>
                    </div>
                </div>

                <div class="d-flex justify-content-between align-items-center my-2">
                    <div>
                        <p class="m-0 p-0">Routing Number:<br>(Transferencias en USA)</p>
                        <p #textoCopiarDos class="m-0 p-0 text-primary fw-bold">267084131</p>
                    </div>
                    <div>
                        <button (click)="copiarAlPortapapeles(textoCopiarDos)">
                            <i class="fa-regular fa-copy text-secondary" style="overflow: hidden;"></i>
                        </button>
                    </div>
                </div>

                <div class="d-flex justify-content-between align-items-center my-2">
                    <div>
                        <p class="m-0 p-0">Beneficiario:</p>
                        <p #textoCopiarTres class="m-0 p-0 text-primary fw-bold">ARCSA SERVICES PROTECTION, LLC</p>
                    </div>
                    <div>
                        <button (click)="copiarAlPortapapeles(textoCopiarTres)">
                            <i class="fa-regular fa-copy text-secondary" style="overflow: hidden;"></i>
                        </button>
                    </div>
                </div>

                <div class="d-flex justify-content-between align-items-center my-2">
                    <div>
                        <p class="m-0 p-0">Dirección:</p>
                        <p #textoCopiarCuatro class="m-0 p-0 text-primary fw-bold">295NE 18th st Miami, FL 33132</p>
                    </div>
                    <div>
                        <button (click)="copiarAlPortapapeles(textoCopiarCuatro)">
                            <i class="fa-regular fa-copy text-secondary" style="overflow: hidden;"></i>
                        </button>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="col-11 mb-1" style="height: 4rem; overflow: hidden;">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="col-11">
                    <div *ngIf="successMessage" class="w-100 alert alert-success desaparece-modal">
                        {{successMessage}}
                    </div>
                    <div *ngIf="errorMessage" class="alert alert-danger desaparece-modal">
                        {{errorMessage}}
                    </div>
                </div>
            </div>
        </div>

        <div class="col-11 mb-5 aparece-dos">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="col-11">
                    <ol>
                        <li>Registra el número de cuenta y el SWIFT Code cuando sea una transferencia internacional.</li>
                        <li>Registra el ROUTING Number cuando sea una transferencia entre bancos de Estados Unidos.</li>
                        <li>Una vez realizada tu inversión, tu cuenta quedará activada y se verá reflejada tu inversión en un lapso de 24 a 48 horas hábiles. </li>
                        <li>Listo, ahora podrás disfrutar de los rendimientos que Arcsa Capital genera para ti. Te recordamos que puedes continuar realizando inversiones en todo momento para generar más rendimientos.</li>
                    </ol>
                </div>
            </div>
        </div>

        <div class="col-11 mb-5 aparece-cuatro">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="col-11 p-2">

                    <button class="btn btn-primary w-100" routerLink="/comprobante02" routerLinkActive="active-link">Enviar Comprobante</button>

                </div>
            </div>
        </div>
    </div>
</div>
