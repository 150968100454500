import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TempDataService } from '../../../../services/temp-data.service';
import { AuthService } from '../../../../services/logged/auth.service';

@Component({
  selector: 'app-perfil-nuevo05',
  templateUrl: './perfil-nuevo05.component.html',
  styles: [
  ]
})

export class PerfilNuevo05Component implements OnInit {

  title = "Confirma los datos de tus beneficiarios";
  beneficiarios: any[] = [];
  isLoading = false;
  errorMessage: string = '';
  successMessage: string = '';

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private tempDataService: TempDataService
  ) {}

  ngOnInit() {
    this.tempDataService.getFormBeneficiarios().subscribe(data => {
      if (!data || data.beneficiarios.length === 0) {
        this.location.back();
      } else {
        this.beneficiarios = data.beneficiarios;
      }
    });
  }

  onSubmit() {
    if (this.beneficiarios.length > 0) {
      this.isLoading = true;
      const token = this.authService.getToken() || '';
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token
      });
  
      this.http.post('',
        { beneficiarios: this.beneficiarios },
        { headers: headers, observe: 'response' }
      ).subscribe({
        next: (response) => {
          if (response.status === 201) {
            this.successMessage = 'Datos enviados correctamente. Redirigiendo...';
            this.isLoading = false;
  
            setTimeout(() => {
              this.router.navigate(['/registro08perfil-nuevo06']);
            }, 3000);
          } else {
            this.errorMessage = 'Respuesta inesperada del servidor: ' + response.status;
            this.isLoading = false;
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorMessage = error.error.message || 'Error al enviar los datos. Inténtalo de nuevo.';
          this.isLoading = false;
        }
      });
    } else {
      this.errorMessage = 'No hay beneficiarios para enviar.';
    }
  }  

  onEdit() {
    this.router.navigate(['/perfil-nuevo04']);
  }
}