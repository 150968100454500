import { HttpClient } from '@angular/common/http'; 
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';  // Make sure OnInit is imported
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProgresoUsuarioService } from '../../../services/progreso-usuario/progreso-usuario.service';  // Import the service

@Component({
  selector: 'app-registro05',
  templateUrl: './registro05.component.html',
  styleUrls: []
})
export class Registro05Component implements OnInit { 

  title = "Datos bancarios";
  
  formBancario: FormGroup;

  errorMessage: string | null = null;
  successMessage: string | null = null;

  constructor( 
    private http: HttpClient, 
    private _location: Location, 
    private router: Router, 
    private cdr: ChangeDetectorRef, 
    private progresoUsuarioService: ProgresoUsuarioService 
  ) {
    this.formBancario = new FormGroup({
      banco: new FormControl('', { validators: [Validators.required]}),
      beneficiario: new FormControl('', { validators: [Validators.required]}),
      nocuenta: new FormControl('', { validators: [Validators.required] }),
      clabe: new FormControl('', { validators: [Validators.required]}),
      codigo_swift: new FormControl('', { validators: [Validators.required]}),
    });
  }

  ngOnInit(): void {
    this.progresoUsuarioService.checkUserProgressAndNavigate();
  }

  get banco(){
    return this.formBancario.get('banco');
  }
  get beneficiario(){
    return this.formBancario.get('beneficiario');
  }
  get nocuenta(){
    return this.formBancario.get('nocuenta');
  }
  get clabe(){
    return this.formBancario.get('clabe');
  }
  get codigo_swift(){
    return this.formBancario.get('codigo_swift');
  }

  onSubmit() {
    this.errorMessage = null;
    this.successMessage = null; 
  
    if (this.formBancario.valid) {
      const formData = this.formBancario.value;
  
      this.http.post('https://login.arcsacapital.com/datos_personales/seccion4', formData).subscribe({
        next: (response) => {
          this.successMessage = 'Datos enviados con éxito.';
          setTimeout(() => {
            this.router.navigate(['/simuladorDatos']);
          }, 2000);
        },
        error: (error) => {
          this.errorMessage = error.error && error.error.message ? error.error.message : 
                              'Error al enviar los datos, intente de nuevo más tarde.';
          this.cdr.markForCheck(); 
        }
      });
    } else {
      this.errorMessage = 'Por favor, complete todos los campos requeridos.';
      this.cdr.markForCheck(); 
    }
  }
}
