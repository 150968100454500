import { Component } from '@angular/core';

@Component({
  selector: 'app-descargo',
  templateUrl: './descargo.component.html',
  styles: [
  ]
})
export class DescargoComponent {

  title = 'Descargo de Responsabilidad y Acuerdo'

}
