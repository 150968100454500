<app-user-status></app-user-status>

<div class="container-fluid contenedor-94 d-flex flex-column justify-content-center">
    <div class="row pb-5 d-flex justify-content-evenly align-items-center">
        <div class="col-11 pb-5">

            <h1 class="text-center">Ingresa tu nueva contraseña</h1>
            
            <form class="col-12 pb-5 d-flex flex-column justify-content-center align-items-center " style="height: 90% !important;"> <!-- id="formLogin" [formGroup]="formLogin" (ngSubmit)="onSubmit()" -->
                <div class="col-11 pb-5 d-flex flex-column justify-content-evenly align-items-center" style="height: 100% !important;">
                    <div>
                        <label class="form-label" for="nuevaContrasena">Nueva contraseña:</label>
                        <input class="form-control mt-2" type="nuevaContrasena" id="nuevaContrasena" formControlName="nuevaContrasena" required>
                        <p class="fs-6 text-terciary fst-italic" style="transform: translateY(0) !important;">La contraseña debe contener al menos 8 caracteres, de los cuales debe incluir al menos una letra mayúscula, una letra minúscula, un número y un caractér especial.</p>
                        <label class="form-label" for="confirmaContrasena">Confirma tu contraseña:</label>
                        <input class="form-control mt-2" type="confirmaContrasena" id="confirmaContrasena" formControlName="confirmaContrasena" required>
                        <p class="fs-6 text-terciary fst-italic" style="transform: translateY(0) !important;">Las contraseñas deben coincidir.</p>
                    </div>
                </div>
                <div class="col-11 contenedor-botones aparece-tres">
                    <button class="w-100 btn btn-primary" (click)="temporal()" >Enviar</button>
                </div>
            </form>
        </div>  
    </div>
</div>

