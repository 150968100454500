import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinksService {
  public whatsappUrl = 'https://api.whatsapp.com/message/SV4MOGRS5XH5F1?autoload=1&app_absent=0';
  public linkedinUrl = 'https://www.linkedin.com/company/arcsa-capital/';
  public instagramUrl = 'https://www.instagram.com/arcsa_capital/';
  public threadsUrl = 'https://www.threads.net/@arcsa_capital';
  public facebookUrl = 'https://www.facebook.com/people/Arcsa-Capital/100077256857809/';
  public youtubeUrl = 'https://www.youtube.com/channel/UCanwpVFYznAreeR6zztvrvw';

  constructor() { }
}
