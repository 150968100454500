<app-user-status></app-user-status>

<div class="container d-flex justify-content-center align-items-center contenedor-94">
    <div class="d-flex flex-column justify-content-evenly align-items-center">
        
        <div class="col-10 d-flex flex-column justify-content-center align-items-center aparece-tres">
            <span class="progresoFel text-center my-2">¡FELICIDADES!</span>
            <h4 class="text-center mt-2 mb-5">Has registrado tu información con éxito</h4>
            <h3 class="my-2">¿Qué sigue?</h3>
            <p class="my-5">Estamos revisando tu información, te contactaremos una vez que hayamos podido corroborar y autorizar el siguiente proceso de tu registro.</p>
        </div>

        <div class="col-11 mt-2 aparece-tres">
            <button class="btn btn-primary w-100" (click)="logout()" routerLink="/home" routerLinkActive="active-link">Cerrar sesión</button>
        </div>

    </div>
</div>  