<app-menu-preregistro></app-menu-preregistro>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">
        
        <div class="col-11 d-flex flex-column justify-content-center align-items-center progresoRegistroCaja aparece" style="--porcentaje: 10">
            <div class="col-11 d-flex justify-content-start align-items-center">
                <p class="text-start m-0 me-1 fs-1 fw-regular">Paso</p>
                <button type="button" class="bg-transparent border-0 d-flex justify-content-center align-items-center" style="height: 2.2rem; width: 2.2rem;" data-bs-toggle="modal" data-bs-target="#modal">
                    <i class="fa-solid fa-circle-info text-primary fs-5 p-1"></i>
                </button>
                <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                        <div class="container">
                            <div class="row d-flex justify-content-center align-items-center">
                                <div class="col-10">
                                    <div class="card">
                                        <div class="card-body p-5">
                                            <div class="cont-btn-close">
                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                  <i class="fa-solid fa-x"></i>
                                                </button>
                                              </div>
                                            <h4 class="card-title text-center text-primary">Pasos</h4>
                                            <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-11 d-flex justify-content-start align-items-center cajaTexto">
                <span class="me-2">2</span>
                <span> de 7</span>
            </div>
            <div class="col-11" style="--porcentaje: 20">
                <svg class="w-75 m-0 progresoRegistro">
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="70" />
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="70" />
                </svg>
            </div>
            
        </div>
        
        <div class="col-11 my-5 d-flex justify-content-center align-items-center aparece-dos">
            <div class="col-11 d-flex justify-content-center align-items-center">
                <i class="p-2 text-secondary fa-solid fa-id-card-clip" style="font-size: 8rem;"></i>
            </div>
        </div>
        
        <div class="col-11 d-flex flex-column justify-content-center align-items-center aparece-tres" style="--porcentaje: 10">
            <div class="col-11">
                <h1>{{ title }}</h1>
                <p>Sube un archivo claro y legible de uno de los siguientes documentos:</p>
                <p>Recuerda que este documento debe estar vigente</p>
            </div>
        </div>

        <!-- <div class="col-11 aparece d-flex justify-content-center align-items-center mt-5 mb-2" style="height: 8rem !important; overflow: hidden;">
            <div class="col-11 d-flex flex-columnjustify-content-start align-items-baseline flex-wrap aparece">
                <div class="alert alert-light" *ngIf="archivoSeleccionado">
                    <p class="fs-3 fw-bold p-0 m-0 pe-2">Primer archivo seleccionado: </p>
                    <p class="p-0 m-0">{{ archivoSeleccionado }}</p>
                </div>
                <div class="alert alert-light" *ngIf="archivoReverso">
                    <p class="fs-3 fw-bold p-0 m-0 pe-2">Segundo archivo seleccionado: </p>
                    <p class="p-0 m-0">{{ archivoReverso }}</p>
                </div>
            </div>
        </div> -->

        <form class="col-11 contenedor-botones mt-5 pt-0 aparece-tres">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center">
                <label class="w-100 input-custom btn btn-primary" style="transform: none !important;" for="file-upload-frente">Frente</label>
                <input type="file" id="file-upload-frente" (change)="handleFileChange($event, 'frente')" hidden>
                
                <label class="w-100 input-custom btn btn-primary mt-2" style="transform: none !important;" for="file-upload-reverso">Reverso</label>
                <input type="file" id="file-upload-reverso" (change)="handleFileChange($event, 'reverso')" hidden>

                <div class="mt-2 w-100">
                    <div class="alert alert-light d-flex flex-wrap justify-content-start align-items-baseline" style="overflow: hidden;" *ngIf="archivoFrente">
                        <p class="fs-3 fw-bold p-0 m-0 pe-2" style="transform: none !important;">Frente: </p>
                        <p class="p-0 m-0" style="transform: none !important;">{{ archivoFrente.name }}</p>
                    </div>
                    
                    <div class="mt-2 alert alert-light d-flex flex-wrap justify-content-start align-items-baseline" style="overflow: hidden;" *ngIf="archivoReverso">
                        <p class="fs-3 fw-bold p-0 m-0 pe-2" style="transform: none !important;">Reverso: </p>
                        <p class="p-0 m-0" style="transform: none !important;">{{ archivoReverso.name }}</p>
                    </div>
                </div>

                <div *ngIf="errorMessage" class="mt-2 w-100 alert alert-danger">
                    {{ errorMessage }}
                  </div>
                  <div *ngIf="isSubmissionSuccessful" class="w-100 alert alert-success">
                    {{ successMessage }}
                </div>

                <div class="w-100 contenedor-botones aparece-tres mt-0 pt-0">
                    <button class="w-100 btn btn-secondary" (click)="uploadFiles()" [disabled]="!archivosSeleccionados">Enviar</button>
                    <a class="d-flex justify-content-center mt-3 link-underline link-underline-opacity-0" routerLink="/registro04" routerLinkActive="active-link">Avanzar con la prueba</a>
                </div>
            </div>
        </form>

    </div>
</div>