import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/logged/auth.guard';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './components/1-home/home.component';
import { PagenotfoundComponent } from './components/3-pagenotfound/pagenotfound.component';
import { FaqsComponent } from './components/5-paginasAisladas/faqs/faqs.component';
import { TerminosComponent } from './components/5-paginasAisladas/terminos/terminos.component';
import { PrivacidadComponent } from './components/5-paginasAisladas/privacidad/privacidad.component';
import { DivulgacionComponent } from './components/5-paginasAisladas/divulgacion/divulgacion.component';
import { DescargoComponent } from './components/5-paginasAisladas/descargo/descargo.component';

import { UserStatusComponent } from './components/4-userStatus/menu/user-status.component';
import { MenuPreregistroComponent } from './components/4-userStatus/preregistro/menu-preregistro.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
// import { NavmenuComponent } from './components/2-navmenu/navmenu.component';

import { LoginComponent } from './components/13-login/login/login.component';
import { RestablecerComponent } from './components/13-login/restablecer/restablecer.component';
import { RestablecerVerificaComponent } from './components/13-login/restablecer-verifica/restablecer-verifica.component';
import { RestablecidaComponent } from './components/13-login/restablecida/restablecida.component';
import { NuevacontrasenaComponent } from './components/13-login/nuevacontrasena/nuevacontrasena.component';
import { AsistenciaComponent } from './components/13-login/asistencia/asistencia.component';

import { Onboarding01Component } from './components/11-onboarding/onboarding01/onboarding01.component';
import { Onboarding02Component } from './components/11-onboarding/onboarding02/onboarding02.component';
import { Onboarding03Component } from './components/11-onboarding/onboarding03/onboarding03.component';
import { Onboarding04Component } from './components/11-onboarding/onboarding04/onboarding04.component';

import { Preregistro01Component } from './components/12-preregistro/preregistro01/preregistro01.component';
import { Preregistro02Component } from './components/12-preregistro/preregistro02/preregistro02.component';
import { Preregistro03Component } from './components/12-preregistro/preregistro03/preregistro03.component';
import { Preregistro04Component } from './components/12-preregistro/preregistro04/preregistro04.component';
import { Preregistro05Component } from './components/12-preregistro/preregistro05/preregistro05.component';

import { SimuladorComponent } from './components/14-simulador/simulador.component';
import { Registro01Component } from './components/15-registro/registro01/registro01.component';
import { Registro02Component } from './components/15-registro/registro02/registro02.component';
import { Registro03Component } from './components/15-registro/registro03/registro03.component';
import { Registro04Component } from './components/15-registro/registro04/registro04.component';
import { Registro05Component } from './components/15-registro/registro05/registro05.component';
import { Registro06Component } from './components/15-registro/registro06/registro06.component';
import { Registro07Component } from './components/15-registro/registro07/registro07.component';
import { Registro08Component } from './components/15-registro/registro08/registro08.component';
import { Registro09Component } from './components/15-registro/registro09/registro09.component';
import { SimuladorDatosComponent } from './components/15-registro/simulador-datos/simulador-datos.component';

import { Comprobante01Component } from './components/16-comprobante/comprobante01/comprobante01.component';
import { Comprobante02Component } from './components/16-comprobante/comprobante02/comprobante02.component';
import { Comprobante03Component } from './components/16-comprobante/comprobante03/comprobante03.component';

import { Contrato01Component } from './components/17-firmaContrato/contrato01/contrato01.component';
import { Contrato02Component } from './components/17-firmaContrato/contrato02/contrato02.component';

import { Status01Component } from './components/15-registro/status/status01/status01.component';
import { Status02Component } from './components/15-registro/status/status02/status02.component';
import { Status03Component } from './components/15-registro/status/status03/status03.component';

import { BannerCuentaComponent } from './components/18-perfil/banner-cuenta/banner-cuenta.component';
import { BotonAgregarComponent } from './components/18-perfil/boton-agregar/boton-agregar.component';

import { Perfil01Component } from './components/18-perfil/perfil01-contratos/perfil01.component';
import { Perfil02Component } from './components/18-perfil/perfil02-contrato/perfil02.component';
import { Perfil03Component } from './components/18-perfil/perfil03-infoContrato/perfil03.component';
import { Perfil07Component } from './components/18-perfil/perfil07-usuario/perfil07-usuario.component';

import { PerfilRetiro01Component } from './components/18-perfil/perfil04-retiro/perfil-retiro01/perfil-retiro01.component';
import { PerfilRetiro02Component } from './components/18-perfil/perfil04-retiro/perfil-retiro02/perfil-retiro02.component';

import { PerfilAumento01Component } from './components/18-perfil/perfil05-aumentoCapital/perfil-aumento01/perfil-aumento01.component';
import { PerfilAumento02Component } from './components/18-perfil/perfil05-aumentoCapital/perfil-aumento02/perfil-aumento02.component';
import { PerfilAumento03Component } from './components/18-perfil/perfil05-aumentoCapital/perfil-aumento03/perfil-aumento03.component';
import { PerfilAumento04Component } from './components/18-perfil/perfil05-aumentoCapital/perfil-aumento04/perfil-aumento04.component';

import { PerfilNuevo01Component } from './components/18-perfil/perfil06-nuevoContrato/perfil-nuevo01/perfil-nuevo01.component';
// import { PerfilNuevo02Component } from './components/18-perfil/perfil05-nuevoContrato/perfil-nuevo02/perfil-nuevo02.component';
import { PerfilNuevo03Component } from './components/18-perfil/perfil06-nuevoContrato/perfil-nuevo03/perfil-nuevo03.component';
import { PerfilNuevo04Component } from './components/18-perfil/perfil06-nuevoContrato/perfil-nuevo04/perfil-nuevo04.component';
import { PerfilNuevo05Component } from './components/18-perfil/perfil06-nuevoContrato/perfil-nuevo05/perfil-nuevo05.component';
import { PerfilNuevo06Component } from './components/18-perfil/perfil06-nuevoContrato/perfil-nuevo06/perfil-nuevo06.component';
import { PerfilNuevo07Component } from './components/18-perfil/perfil06-nuevoContrato/perfil-nuevo07/perfil-nuevo07.component';
// import { NavegacionService } from './services/navegacion.service';

const routes: Routes = [

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },

  { path: 'login', component: LoginComponent },
  { path: 'restablecer', component: RestablecerComponent },
  { path: 'restablecerVerifica', component: RestablecerVerificaComponent },
  { path: 'restablecida', component: RestablecidaComponent },
  { path: 'nuevaContraseña', component: NuevacontrasenaComponent },
  { path: 'asistencia', component: AsistenciaComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'terminos', component: TerminosComponent },
  { path: 'privacidad', component: PrivacidadComponent },
  { path: 'divulgacion', component: DivulgacionComponent },
  { path: 'descargo', component: DescargoComponent },
  { path: 'onboarding01', component: Onboarding01Component },
  { path: 'onboarding02', component: Onboarding02Component },
  { path: 'onboarding03', component: Onboarding03Component },
  { path: 'onboarding04', component: Onboarding04Component },
  { path: 'preregistro01', component: Preregistro01Component },
  { path: 'preregistro02', component: Preregistro02Component },
  { path: 'preregistro03', component: Preregistro03Component },
  { path: 'preregistro04', component: Preregistro04Component },
  { path: 'preregistro05', component: Preregistro05Component },

  { path: 'simulador', component: SimuladorComponent, canActivate: [AuthGuard] },

  { path: 'registro01', component: Registro01Component, canActivate: [AuthGuard] },
  { path: 'registro02', component: Registro02Component, canActivate: [AuthGuard] },
  { path: 'registro03', component: Registro03Component, canActivate: [AuthGuard] },
  { path: 'registro04', component: Registro04Component, canActivate: [AuthGuard] },
  { path: 'registro05', component: Registro05Component, canActivate: [AuthGuard] },
  { path: 'simuladorDatos', component: SimuladorDatosComponent, canActivate: [AuthGuard] },
  { path: 'registro06', component: Registro06Component, canActivate: [AuthGuard] },
  { path: 'registro07', component: Registro07Component, canActivate: [AuthGuard] },
  { path: 'registro08', component: Registro08Component, canActivate: [AuthGuard] },
  { path: 'registro09', component: Registro09Component, canActivate: [AuthGuard] },
  
  { path: 'comprobante01', component: Comprobante01Component, canActivate: [AuthGuard] },
  { path: 'comprobante02', component: Comprobante02Component, canActivate: [AuthGuard] },
  { path: 'comprobante03', component: Comprobante03Component, canActivate: [AuthGuard] },
  
  { path: 'contrato01', component: Contrato01Component, canActivate: [AuthGuard] },
  { path: 'contrato02', component: Contrato02Component, canActivate: [AuthGuard] },
  
  { path: 'status01', component: Status01Component, canActivate: [AuthGuard] },
  { path: 'status02', component: Status02Component, canActivate: [AuthGuard] },
  { path: 'status03', component: Status03Component, canActivate: [AuthGuard] },
  
  { path: 'bannerCuenta', component: BannerCuentaComponent, canActivate: [AuthGuard] },
  { path: 'botonAgregar', component: BotonAgregarComponent, canActivate: [AuthGuard] },

  { path: 'perfil01', component: Perfil01Component, canActivate: [AuthGuard] },
  { path: 'perfil02', component: Perfil02Component, canActivate: [AuthGuard] },
  { path: 'perfil03', component: Perfil03Component, canActivate: [AuthGuard] },
  { path: 'perfil07', component: Perfil07Component, canActivate: [AuthGuard] },
  
  { path: 'perfil-retiro01', component: PerfilRetiro01Component, canActivate: [AuthGuard] },
  { path: 'perfil-retiro02', component: PerfilRetiro02Component, canActivate: [AuthGuard] },
  
  { path: 'perfil-aumento01', component: PerfilAumento01Component, canActivate: [AuthGuard] },
  { path: 'perfil-aumento02', component: PerfilAumento02Component, canActivate: [AuthGuard] },
  { path: 'perfil-aumento03', component: PerfilAumento03Component, canActivate: [AuthGuard] },
  { path: 'perfil-aumento04', component: PerfilAumento04Component, canActivate: [AuthGuard] },
  
  { path: 'perfil-nuevo01', component: PerfilNuevo01Component, canActivate: [AuthGuard] },
  // { path: 'perfil-nuevo02', component: PerfilNuevo02Component, canActivate: [AuthGuard] },
  { path: 'perfil-nuevo03', component: PerfilNuevo03Component, canActivate: [AuthGuard] },
  { path: 'perfil-nuevo04', component: PerfilNuevo04Component, canActivate: [AuthGuard] },
  { path: 'perfil-nuevo05', component: PerfilNuevo05Component, canActivate: [AuthGuard] },
  { path: 'perfil-nuevo06', component: PerfilNuevo06Component, canActivate: [AuthGuard] },
  { path: 'perfil-nuevo07', component: PerfilNuevo07Component, canActivate: [AuthGuard] },
  
  { path: '**', component: PagenotfoundComponent }
];


@NgModule({
  declarations: [
    LoginComponent,
    RestablecerComponent,
    RestablecerVerificaComponent,
    RestablecidaComponent,
    AsistenciaComponent,
    NuevacontrasenaComponent,
    SimuladorComponent,
    UserStatusComponent,
    MenuPreregistroComponent,
    FaqsComponent,
    TerminosComponent,
    PrivacidadComponent,
    DivulgacionComponent,
    DescargoComponent,

    HomeComponent,
    PagenotfoundComponent,
    Onboarding01Component,
    Onboarding02Component,
    Onboarding03Component,
    Onboarding04Component,

    Preregistro01Component,
    Preregistro02Component,
    Preregistro03Component,
    Preregistro04Component,
    Preregistro05Component,

    Registro01Component,
    Registro02Component,
    Registro03Component,
    Registro04Component,
    Registro05Component,
    Registro06Component,
    Registro07Component,
    Registro08Component,
    Registro09Component,
    SimuladorDatosComponent,

    Comprobante01Component,
    Comprobante02Component,
    Comprobante03Component,

    Contrato01Component,
    Contrato02Component,

    Status01Component,
    Status02Component,
    Status03Component,
    
    BannerCuentaComponent,
    BotonAgregarComponent,

    Perfil01Component,
    Perfil02Component,
    Perfil03Component,
    Perfil07Component,
    
    PerfilRetiro01Component,
    PerfilRetiro02Component,
    
    PerfilAumento01Component,
    PerfilAumento02Component,
    PerfilAumento03Component,
    PerfilAumento04Component,
    
    PerfilNuevo01Component,
    // PerfilNuevo02Component,
    PerfilNuevo03Component,
    PerfilNuevo04Component,
    PerfilNuevo05Component,
    PerfilNuevo06Component,
    PerfilNuevo07Component,

    CustomDatePipe
  ],
  imports: [
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    /* UploadWidgetModule, */
  ],
  providers:[
  ],
  bootstrap: [
    AppRoutingModule
  ],
  exports: [
    RouterModule,
    BannerCuentaComponent,
    BotonAgregarComponent,
  ]
})

export class AppRoutingModule { }