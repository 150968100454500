import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core'; 
import { AuthService } from '../../../services/logged/auth.service';
import { Router } from '@angular/router';
import { ProgresoUsuarioService } from '../../../services/progreso-usuario/progreso-usuario.service'; 

@Component({
  selector: 'app-registro03',
  templateUrl: './registro03.component.html',
  styleUrls: [],
})
export class Registro03Component implements OnInit { 

  title = 'Sube tu identificación';
  
  errorMessage: string | null = null;
  isSubmissionSuccessful: boolean = false;
  successMessage: string = 'Archivos y datos subidos con éxito';
  
  public archivoFrente?: File;
  public archivoFrente64: any;
  public archivoReverso?: File;
  public archivoReverso64: any;
  private MAX_SIZE: number = 2 * 1024 * 1024;

  constructor(
    private http: HttpClient, 
    private authService: AuthService,
    private router: Router,
    private progresoUsuarioService: ProgresoUsuarioService 
    ) {}

  ngOnInit(): void {
    this.progresoUsuarioService.checkUserProgressAndNavigate();
  }

  handleFileChange(event: any, tipo: 'frente' | 'reverso'): void {
    const file = event.target.files[0];
    if (!file) {
      this.errorMessage = 'Ningún archivo seleccionado';
      return;
    }

    if (file.size > this.MAX_SIZE) {
      this.errorMessage = 'El archivo supera el límite de 2MB';
      return;
    }

    tipo === 'frente' ? (this.archivoFrente = file) : (this.archivoReverso = file);
    this.errorMessage = null;
}


  get archivosSeleccionados(): boolean {
    return !!this.archivoFrente && !!this.archivoReverso;
  }

  convertImageToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  async uploadFiles() {
    this.errorMessage = null;
    this.isSubmissionSuccessful = false;

    if (!this.archivoFrente || !this.archivoReverso) {
      this.errorMessage = 'Debe seleccionar ambos archivos antes de enviar.';
      return;
    }

    let formData = new FormData();
    formData.append('imagen_a', this.archivoFrente);
    formData.append('imagen_b', this.archivoReverso);

    this.http.post('https://login.arcsacapital.com/datos_personales/seccion2', formData, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.authService.getToken()}`
      })
    }).subscribe({
      next: (response) => {
        this.isSubmissionSuccessful = true;
        setTimeout(() => {
          this.router.navigate(['/registro04']);
        }, 2000);
      },
      error: (error) => {
        if (error.error instanceof ErrorEvent) {
          this.errorMessage = 'Error del lado del cliente o red: ' + error.error.message;
        } else {
          this.errorMessage = error.error.message || 'Error desconocido al subir archivos';
        }
      }
    });
  }
}
