<app-user-status [showBackButton]="true"></app-user-status>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">

          <div class="col-11 aparece">
              <h1>{{ title }}</h1>
          </div>

        <div class="col-11 aparece-dos">
            <div class="accordion" id="accordionExample">

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUno" aria-expanded="false" aria-controls="collapseUno">
                      <h4>¿De qué se trata Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseUno" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <p>Somos un fondo de inversión privado que opera en Miami, Florida. Creamos un portafolio diversificado que invierte en diferentes activos financieros con el objetivo de obtener rendimientos superiores a los que ofrece cualquier institución bancaria. Todas las inversiones son manejadas y administradas por expertos en la bolsa de valores y otros sectores; de tal forma que, trabajamos de la mano de partners como Blackstone, Polar y Bridgewater, quienes nos ayudan a reducir los riesgos de inversión y cuidar del capital de nuestros socios inversionistas.</p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDos" aria-expanded="false" aria-controls="collapseDos">
                      <h4>¿Qué es un fondo de inversión privado?</h4>
                    </button>
                  </h2>
                  <div id="collapseDos" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Una sociedad entre 2 o más personas para invertir en uno o más instrumentos. No es captación de recursos, es una sociedad para multiplicar capitales de cada uno de sus integrantes.</p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTres" aria-expanded="false" aria-controls="collapseTres">
                      <h4>¿Quién certifica y/o regula a Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseTres" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Estamos certificados por el gobierno de Florida, entidad que avala nuestras operaciones financieras en Estados Unidos. Además, todas las transacciones son realizadas a través de instituciones bancarias dentro del territorio americano.
                        <br>
                        <br>
                        Estamos regulados por la FED (Sistema de Reserva Federal), institución que se encarga de validar las operaciones bancarias, mantener la estabilidad del sistema financiero y proveer de servicios financieros a instituciones de depósito, al gobierno estadounidense y a instituciones extranjeras oficiales.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCuatro" aria-expanded="false" aria-controls="collapseCuatro">
                      <h4>¿Cuál es la fecha de certificación por el estado de Florida?</h4>
                    </button>
                  </h2>
                  <div id="collapseCuatro" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Nuestro certificado fue emitido en mayo de 2021 y se debe renovar cada año.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCinco" aria-expanded="false" aria-controls="collapseCinco">
                      <h4>¿Quién audita las operaciones de Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseCinco" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Todos nuestros partners tienen todas las regulaciones necesarias para mover activos líquidos en conjunto de una empresa de administración autorizada por el gobierno. Además, están regulados por la SEC (Securities and Exchange Commission), así como por el IRS (Internal Revenue Service).</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeis" aria-expanded="false" aria-controls="collapseSeis">
                      <h4>¿Por qué la SEC no regula a Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseSeis" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Las regulaciones de la SEC son principalmente para fondos públicos y con activos líquidos. Para los activos que manejamos se requiere solo una autorización por la estructura que tenemos, un fondo de fondos, privado y limitado.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSiete" aria-expanded="false" aria-controls="collapseSiete">
                      <h4>¿Por qué invertir en Estados Unidos si la inflación en ese país es superior a la inflación en México?</h4>
                    </button>
                  </h2>
                  <div id="collapseSiete" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Porque el dólar ha demostrado ser la moneda más sólida y estable de todo el mundo ya que el petróleo y todas las transacciones internacionales se hacen por medio de ese tipo de cambio.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOcho" aria-expanded="false" aria-controls="collapseOcho">
                      <h4>¿Por qué Arcsa Capital no aparece en la Comisión Nacional Bancaria y de Valores (CNBV) como autorizada para captar capital de los inversionistas?</h4>
                    </button>
                  </h2>
                  <div id="collapseOcho" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Porque no operamos financieramente en México y porque no somos captadores de capital ya que nuestro negocio se trata de invitar a nuevos inversionistas a participar en una sociedad privada.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNueve" aria-expanded="false" aria-controls="collapseNueve">
                      <h4>¿Por qué confiar en Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseNueve" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <ul>
                        <li>
                            <p>Porque no tienes que invitar a gente de forma permanente para que genere continuidad.</p>
                        </li>
                        <li>
                            <p>Porque hay un contrato de por medio con la empresa.</p>
                        </li>
                        <li>
                            <p>Porque tenemos oficinas físicas en Estados Unidos y México.</p>
                        </li>
                        <li>
                            <p>Porque el Certificado existe en la página del gobierno de Florida.</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDiez" aria-expanded="false" aria-controls="collapseDiez">
                      <h4>¿Por qué Arcsa Capital no está en el RNV?</h4>
                    </button>
                  </h2>
                  <div id="collapseDiez" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Nosotros compramos acciones de compañías americanas en la bolsa americana; no es así en la bolsa mexicana de compañías mexicanas.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnce" aria-expanded="false" aria-controls="collapseOnce">
                      <h4>¿Por qué existe la SAPI de México?</h4>
                    </button>
                  </h2>
                  <div id="collapseOnce" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>La SAPI de México solo es una compañía administrativa más no de administración de activos, por el momento.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDoce" aria-expanded="false" aria-controls="collapseDoce">
                      <h4>¿Cómo es que Arcsa Capital asegura los rendimientos?</h4>
                    </button>
                  </h2>
                  <div id="collapseDoce" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Porque es renta fija en activos de inmuebles, antes de invertir ya se sabe cuánto se va a ganar. Con las acciones sucede lo mismo, ya que se usa tecnología financiera para lograrlo junto con nuestros partners.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTrece" aria-expanded="false" aria-controls="collapseTrece">
                      <h4>¿Qué tipo de contrato firmo con Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseTrece" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Contrato de Asociación en Participación.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCatorce" aria-expanded="false" aria-controls="collapseCatorce">
                      <h4>¿Cuál es el proceso para empezar a invertir?</h4>
                    </button>
                  </h2>
                  <div id="collapseCatorce" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <ol>
                        <li>
                            <p>Elegir el horizonte de tiempo y el monto.</p>
                        </li>
                        <li>
                            <p>Se hace investigación del inversor.</p>
                        </li>
                        <li>
                            <p>Se deposita a la cuenta del fondo y comienza a generar rendimientos.</p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseQuince" aria-expanded="false" aria-controls="collapseQuince">
                      <h4>¿Cómo funciona Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseQuince" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Reunimos los mejores portafolios y estrategias de inversión del mundo moderno para generar rendimientos superiores a los que ofrece la banca tradicional. Trabajamos a través de un contrato que convierte en socios a nuestros inversionistas.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDieciseis" aria-expanded="false" aria-controls="collapseDieciseis">
                      <h4>¿Cómo ayuda Arcsa Capital a mis inversiones?</h4>
                    </button>
                  </h2>
                  <div id="collapseDieciseis" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Colaboramos con traders y brokers expertos en los mercados financieros de Estados Unidos que nos ayudan a planificar la inversión de tu dinero para que recibas los mejores rendimientos.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDiecisiete" aria-expanded="false" aria-controls="collapseDiecisiete">
                      <h4>¿Quiénes pueden invertir en Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseDiecisiete" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Contamos con una cartera variada de inversionistas que abarca tanto empresas como personas físicas que tengan un capital mínimo de $15,000 USD.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDieciocho" aria-expanded="false" aria-controls="collapseDieciocho">
                      <h4>¿Cuánto es el monto mínimo que puedo invertir?</h4>
                    </button>
                  </h2>
                  <div id="collapseDieciocho" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>El monto mínimo es de $15,000 USD, entre más grande sea el monto, más incrementarán los rendimientos.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDiecinueve" aria-expanded="false" aria-controls="collapseDiecinueve">
                      <h4>¿Cómo abro una cuenta en Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseDiecinueve" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <p>Sigue los siguientes pasos y activa tu cuenta:</p>

                        <ol>
                            <li>
                                <p>Descarga nuestra app en Google Play Store o App Store.</p>
                            </li>
                            <li>
                                <p>Registra tus datos personales.</p>
                            </li>
                            <li>
                                <p>Sube los documentos solicitados para verificar tu identidad. *(Arcsa Capital hará una revisión KYC para saber que los fondos son de procedencia lícita y que el usuario cumple con los requisitos de un inversor certificado o bien, un inversor extranjero transparente)</p>
                            </li>
                            <li>
                                <p>Una vez verificada tu identidad podrás comenzar a invertir.</p>
                            </li>
                        </ol>
                        
                        <i>*Debes considerar cuidadosamente los objetivos de inversión, los riesgos, el equilibrio, la diversificación y la seguridad antes de invertir. El brochure debe leerse detenidamente antes de invertir en el Fondo.
                            <br>
                            <br>
                            Las inversiones en el Fondo son depósitos bancarios y no son garantizadas por Arcsa Capital ni por ninguna otra parte debido a la naturaleza de los mercados. Sin embargo nuestro enfoque siempre estará en la protección de los capitales por medio de la gestión de riesgo lo cual siempre será nuestra prioridad.
                            <br>
                            <br>
                            Los valores descritos en el folleto no se ofrecen para la venta en los estados de Nebraska o Dakota del Norte ni a personas residentes o ubicadas en dichos estados. No se aceptará ninguna suscripción para la venta de acciones del Fondo de ninguna persona residente o ubicada en Nebraska o Dakota del Norte.</i>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVeinte" aria-expanded="false" aria-controls="collapseVeinte">
                      <h4>¿Por qué tengo que proporcionar información personal (TAX ID /Dirección, ETC)?</h4>
                    </button>
                  </h2>
                  <div id="collapseVeinte" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Arcsa Capital está obligado a completar los procedimientos de verificación de identidad para conocer a su cliente (KYC). Como tal, solicitamos que verifiques tu información personal como tu dirección, fecha de nacimiento y número TaxID. Esta información también es necesaria para emitir un formato W8/K1/1099 por cada inversión al final del año.
                        <br>
                        <br>
                        Para obtener más información sobre la política de privacidad de Arcsa Capital, consulta <a routerLink="/politicaPriv" routerLinkActive="active-link">aquí.</a> También utilizamos una aplicación de terceros para transacciones, SynapseFI.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVeintiuno" aria-expanded="false" aria-controls="collapseVeintiuno">
                      <h4>¿Cómo aumento mi capital de inversión?</h4>
                    </button>
                  </h2>
                  <div id="collapseVeintiuno" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Dentro de la app, tienes la opción "Aumento de capital", sólo debes dar clic y subir tu comprobante de transferencia bancaria.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVeintidos" aria-expanded="false" aria-controls="collapseVeintidos">
                      <h4>¿Cuándo puedo retirar mi inversión de Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseVeintidos" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Es importante que sepas que el capital que inviertes siempre será tuyo, por lo tanto, sólo debes esperar a que termine el plazo de tu contrato y puedes retirarlo de forma íntegra, o bien, reinvertirlo. Los rendimientos puedes retirarlos de forma anual y debes notificar con un mes de anticipación si quieres renovar contrato o retirar tu dinero.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVeintitres" aria-expanded="false" aria-controls="collapseVeintitres">
                      <h4>¿Puedo cancelar mi cuenta en cualquier momento?</h4>
                    </button>
                  </h2>
                  <div id="collapseVeintitres" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>No, es necesario que se cumpla el tiempo mínimo estipulado en tu contrato.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVeinticuatro" aria-expanded="false" aria-controls="collapseVeinticuatro">
                      <h4>¿Puedo reinvertir los rendimientos de mi cuenta?</h4>
                    </button>
                  </h2>
                  <div id="collapseVeinticuatro" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Sí, esto es posible por medio de la App de Arcsa Capital, es importante que el monto a reinvertir sea mayor a $15,000 USD.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <h4>¿Debo pagar impuestos por invertir en Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Sí, Arcsa Capital te entregará los rendimientos menos la retención que las autoridades nos exigen; así mismo, en el caso de los inversionistas extranjeros se entrega el documento W8 que avala ante el gobierno de EE.UU que ya se cumplió con el respectivo pago de impuestos, lo que evitará que paguen doble tributación (favor revisar acuerdo con tu país). En el caso de México y Estados Unidos el impuesto es del 10%, mientras que para el resto del mundo es del 15%.</p>
                    </div>
                  </div>
                </div>
                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <h4>¿Por qué debo elegir Arcsa Capital?</h4>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Porque gracias a la diversificación del fondo de inversión, el rendimiento está construido con una tasa fija y tu capital siempre está respaldado por nuestros partners y nuestra corporación.</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- <div class="w-100 my-5 d-flex flex-column justify-content-center align-items-center aparece-tres">
                <a class="col-12 text-center my-5" routerLink="/asistencia" routerLinkActive="active-link">"Regresar"</a>
            </div> -->
    </div>
</div>