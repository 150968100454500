import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';
import { TempDataService } from '../temp-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  private userDetailsUrl = 'https://login.arcsacapital.com/datos_personales/usuario';

  constructor(
    private http: HttpClient,
    private router: Router,
    private tempDataService: TempDataService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(null);
    this.loadInitialToken();
  }

  private loadInitialToken(): void {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      this.currentUserSubject.next(decoded);
    }
  }

  public get currentUser(): Observable<any> {
    return this.currentUserSubject.asObservable();
  }

  login(email: string, contrasena: string): Observable<any> {
    return this.http.post<any>('https://login.arcsacapital.com/login', { email, contrasena })
      .pipe(tap(res => {
        localStorage.setItem('token', res.token);
        this.currentUserSubject.next(jwtDecode(res.token));
      }));
  }

  obtenerDatosUsuario(): Observable<any> {
    return this.http.get<any>(this.userDetailsUrl).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  logout(): void {
    localStorage.removeItem('token');
    this.tempDataService.clearFormDatosPersonales(); 
    this.tempDataService.clearFormBeneficiarios(); 
    this.tempDataService.clearFormAumentoInversion();
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get loggedIn(): boolean {
    const token = localStorage.getItem('token');
    return token !== null && !this.isTokenExpired();
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  public isTokenExpired(): boolean {
    const token = localStorage.getItem('token');
    if (!token) return true;

    try {
      const decoded: any = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        console.log('Token expirado.');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error decodificando el token:', error);
      return true;
    }
  }

  public getUserIdFromToken(): string | null {
    const token = this.getToken();
    if (!token) return null;

    try {
      const decoded: any = jwtDecode(token);
      return decoded.user_id;
    } catch (error) {
      console.error('Error decodificando el token:', error);
      return null;
    }
  }
}