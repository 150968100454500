import { Component} from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { TempDataService } from '../../../../services/temp-data.service';

interface Beneficiario {
  nombres: string;
  apellidos: string;
  parentesco: string;
  fechanac: Date;
  porcentaje: number;
}

@Component({
  selector: 'app-perfil-nuevo04',
  templateUrl: './perfil-nuevo04.component.html',
  styles: [
  ]
})
export class PerfilNuevo04Component{

  title = "Beneficiarios";
  formDatos: FormGroup;

  constructor(
    private router: Router,
    private tempDataService: TempDataService,
    ) {
    this.formDatos = new FormGroup({
      beneficiarios: new FormArray([this.crearBeneficiario()], [this.validadorSumaPorcentajes()])
    });
  }

  crearBeneficiario(): FormGroup {
    return new FormGroup({
      nombres: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ\s]+$/)]),
      apellidos: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ\s]+$/)]),
      parentesco: new FormControl('', Validators.required),
      fechanac: new FormControl('', [Validators.required, this.mayorDeEdadValidator()]),
      porcentaje: new FormControl('', [Validators.required, Validators.min(1), Validators.max(100)])
    });
  }

  agregarBeneficiario() {
    const beneficiariosArray = this.formDatos.get('beneficiarios') as FormArray;
    beneficiariosArray.push(this.crearBeneficiario());
  }

  removerBeneficiario(index: number) {
    const beneficiariosArray = this.formDatos.get('beneficiarios') as FormArray;
    beneficiariosArray.removeAt(index);
  }

  get beneficiarios(): FormArray {
    return this.formDatos.get('beneficiarios') as FormArray;
  }

  mayorDeEdadValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valor = control.value;
      const hoy = new Date();
      const fechaLimite = new Date(hoy.getFullYear() - 18, hoy.getMonth(), hoy.getDate());
      return new Date(valor) <= fechaLimite ? null : { 'menorDeEdad': { value: control.value } };
    };
  }

  validadorSumaPorcentajes(): ValidatorFn {
    return (formArray: AbstractControl): ValidationErrors | null => {
      let total = 0;
      const controls = (formArray as FormArray).controls;
      for (const control of controls) {
        const porcentaje = control.get('porcentaje')?.value;
        total += Number(porcentaje);
      }
      return total === 100 ? null : { 'sumaPorcentajesIncorrecta': true };
    };
  }

  get validadorSumaPorcentajesSuccess(): boolean {
    let total = 0;
    if (this.beneficiarios && this.beneficiarios.controls) {
      this.beneficiarios.controls.forEach(control => {
        const porcentaje = control.get('porcentaje')?.value;
        if (porcentaje !== null && porcentaje !== undefined) {
          total += +porcentaje;
        }
      });
      console.log(total);
    }
    return total === 100;
  }

  getPorcentajeTotal(): number {
    let total = 0;
    this.beneficiarios.controls.forEach(control => {
        const porcentaje = control.get('porcentaje')?.value;
        if (porcentaje !== null && porcentaje !== undefined) {
            total += +porcentaje;
        }
    });
    return total;
  }

  onSubmit() {
    if (this.formDatos.valid) {
      this.tempDataService.setFormBeneficiarios(this.formDatos.value);
      this.router.navigate(['/perfil-nuevo05']);
    }
  }
}