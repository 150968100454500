import { Component } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-onboarding04',
  templateUrl: './onboarding04.component.html',
  styleUrls: ['./onboarding04.component.css']
})
export class Onboarding04Component {

  constructor (private router: Router){ }

  navegarAnt(){
    this.router.navigate(['/onboarding03']);
  };
  
  navegar(){
    this.router.navigate(['/preregistro01']);
  };


}
