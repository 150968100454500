<app-user-status></app-user-status>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">
        
        <div class="col-11 my-5 d-flex justify-content-center align-items-center aparece">
            <div class="col-11 d-flex justify-content-center align-items-center">
                <i class="py-2 text-secondary fa-solid fa-file-arrow-up" style="font-size: 8rem;"></i>
            </div>
        </div>
        
        <div class="col-11 d-flex flex-column justify-content-center align-items-center aparece-dos" style="--porcentaje: 10">
            <div class="col-11">
                <h1>{{ title }}</h1>
                <p>Sube un archivo claro y legible de uno de los siguientes documentos:</p>
                <p>Recuerda que este documento debe estar vigente</p>
            </div>
        </div>

        <form class="col-11 contenedor-botones mt-5 pt-0 aparece-tres" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="col-11 d-flex flex-column">
                <label class="w-100 input-custom btn btn-primary" style="transform: none !important;" for="file-upload">Subir comprobante</label>
                <input type="file" (change)="onFileSelect($event)" id="file-upload" formControlName="archivo" hidden>

                <div class="alert alert-light mt-3 d-flex flex-wrap justify-content-start align-items-baseline" style="overflow: hidden;" *ngIf="archivoSeleccionado">
                    <p class="fs-3 fw-bold p-0 m-0 pe-2" style="transform: none !important;">Archivo seleccionado: </p>
                    <p class="p-0 m-0" style="transform: none !important;">{{ archivoSeleccionado.name }}</p>
                </div>

                <div *ngIf="errorMessage" class="alert alert-danger">
                    {{ errorMessage }}
                </div>
                <div *ngIf="successMessage" class="alert alert-success">
                    {{ successMessage }}
                </div>

                <div class="w-100 contenedor-botones aparece-tres">
                    <button class="w-100 btn btn-secondary" type="submit" *ngIf="archivoSeleccionado">Enviar</button>
                    <a class="d-flex justify-content-center mt-3 link-underline link-underline-opacity-0" routerLink="/comprobante03" routerLinkActive="active-link">Avanzar con la prueba</a>
                </div>

            </div>
        </form>
        
    </div>
</div>


