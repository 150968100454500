<app-user-status [showBackButton]="true"></app-user-status>

<app-banner-cuenta></app-banner-cuenta>

<div class="container-fluid contenedor-94" style="padding: 0 !important;">
    <div class="row d-flex justify-content-evenly align-items-center">

        <div class="col-11 mb-3 aparece">
            <h1>{{ title }}</h1>
        </div>

        <div class="col-11 mb-3 aparece-tres"> 
            <div class="contenedor-tarjetas">
                <div class="tarjetas mt-2 mb-5">
                    <table class="tarjeta tarjeta-light">
                        <!-- <tr >
                            <td>
                                <p class="text-primary fw-regular fs-2">Cliente:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ nombreUsuario }}</p>
                            </td>
                        </tr> -->

                        <!-- <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Contrato:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ contrato }}</p>
                            </td>
                        </tr> -->

                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Titular:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ nombres }}</p>
                                <p>{{ apellidos }}</p>
                            </td>
                        </tr>

                        <!-- <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Inicio del contrato:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ fechaInicio }}</p>
                            </td>
                        </tr>
                        
                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Fecha de término:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ fechaTermino }}</p>
                            </td>
                        </tr> -->
                        
                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Plazo:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ plazoAnios }} años</p>
                            </td>
                        </tr>
                        
                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Porcentaje:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ porcentaje }} %</p>
                            </td>
                        </tr>

                    </table>
                
                </div>
            </div>
        </div>

    </div>