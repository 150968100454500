import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboarding01',
  templateUrl: './onboarding01.component.html',
  styleUrls: ['./onboarding01.component.css']
})
export class Onboarding01Component {

  constructor (private router: Router){ }

  navegar(){
    this.router.navigate(['/onboarding02']);
  };

}
