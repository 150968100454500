<app-user-status></app-user-status>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">

        <!-- Pasos -->
        <div class="col-11 d-flex flex-column justify-content-center align-items-center progresoRegistroCaja aparece" style="--porcentaje: 10">
            <div class="col-11 d-flex justify-content-start align-items-center">
                <p class="text-start m-0 me-1 fs-1 fw-regular">Paso</p>
                <button type="button" class="bg-transparent border-0 d-flex justify-content-center align-items-center" style="height: 2.2rem; width: 2.2rem;" data-bs-toggle="modal" data-bs-target="#modal">
                    <i class="fa-solid fa-circle-info text-primary fs-5 p-1"></i>
                </button>
                <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                        <div class="container">
                            <div class="row d-flex justify-content-center align-items-center">
                                <div class="col-10">
                                    <div class="card">
                                        <div class="card-body p-5">
                                            <div class="cont-btn-close">
                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                  <i class="fa-solid fa-x"></i>
                                                </button>
                                              </div>
                                            <h4 class="card-title text-center text-primary">Pasos</h4>
                                            <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-11 d-flex justify-content-start align-items-center cajaTexto">
                <span class="me-2">3</span>
                <span> de 6</span>
            </div>
            <div class="col-11" style="--porcentaje: 30">
                <svg class="w-75 m-0 progresoRegistro">
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="60" />
                    <line x1="2" y1="1" x2="95%" y2="1" pathLength="60" />
                </svg>
            </div>
            
        </div>

        <!-- Form -->
        <div class="col-11 mb-5 aparece-dos">
            <form class="d-flex flex-column justify-content-center align-items-center" id="formDatos" [formGroup]="formDatos" (ngSubmit)="onSubmit()">
                <div class="col-11 aparece">
                  <h1>{{ title }}</h1>
                </div>
                <div class="col-11 aparece-dos">
                    
                    <p>En caso de fallecimiento, una o más personas pueden recibir el dinero de tu cuenta.</p>
                    <p class="mt-1 fs-6 text-primary fst-italic">Es obligatorio llenar al menos un beneficiario</p>
                    <p class="mt-1 mb-5 fs-6 text-primary fst-italic">La suma total del porcentaje debe ser 100%</p>
                    
                    <div formArrayName="beneficiarios">
                        <div class="aparece" *ngFor="let beneficiario of beneficiarios.controls; let i = index" [formGroupName]="i">
                            <h2 class="mt-4 text-primary fw-regular" style="transform: translate(0, .5rem) !important; font-family: 'Inter Tight', sans-serif;">
                                Beneficiario {{i + 1}}
                            </h2>

                            <label class="form-label" for="nombres">Nombre(s):</label>
                            <div class="d-flex" style="position: relative;">
                                <input class="form-control mb-0 me-2" type="text" id="nombres" formControlName="nombres" required>
                                <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalDos">
                                    <i class="fa-solid fa-circle-info text-primary p-1"></i>
                                </button>
                                <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalDos" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                        <div class="container">
                                            <div class="row d-flex justify-content-center align-items-center">
                                                <div class="col-10">
                                                    <div class="card">
                                                        <div class="card-body p-5">
                                                            <div class="cont-btn-close">
                                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                                    <i class="fa-solid fa-x"></i>
                                                                </button>
                                                                </div>
                                                            <h4 class="card-title text-center text-primary">Nombres</h4>
                                                            <p class="card-text text-center">Info adicional</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <label class="form-label" for="nombres">Apellido(s):</label>
                            <div class="d-flex" style="position: relative;">
                                <input class="form-control mb-0 me-2" type="text" id="apellidos" formControlName="apellidos" required>
                                <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalTres">
                                    <i class="fa-solid fa-circle-info text-primary p-1"></i>
                                </button>
                                <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalTres" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                        <div class="container">
                                            <div class="row d-flex justify-content-center align-items-center">
                                                <div class="col-10">
                                                    <div class="card">
                                                        <div class="card-body p-5">
                                                            <div class="cont-btn-close">
                                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                                    <i class="fa-solid fa-x"></i>
                                                                </button>
                                                                </div>
                                                            <h4 class="card-title text-center text-primary">Apellidos</h4>
                                                            <p class="card-text text-center">Info adicional</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <label class="form-label" for="fechanac-{{i}}">Fecha de Nacimiento:</label>
                            <div class="date-input-container d-flex" style="position: relative;">
                                <div class="w-100 date-input-container me-2" style="position: relative;">
                                    <input type="date" class="form-control mb-0 me-2" id="fechanac-{{i}}" style="padding-right: 30px; background-color: #ffffff00;" formControlName="fechanac" required>
                                    <i class="fs-4 text-primary fas fa-calendar-alt" style="position: absolute; right: 10px; bottom: 12px; cursor: pointer;"></i>
                                </div>
                                <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalCuatro">
                                    <i class="fa-solid fa-circle-info text-primary p-1"></i>
                                </button>
                                <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalCuatro" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                        <div class="container">
                                            <div class="row d-flex justify-content-center align-items-center">
                                                <div class="col-10">
                
                                                    <div class="card">
                                                        <div class="card-body p-5">
                                                            <div class="d-flex justify-content-end align-items-end">
                                                                <button type="button" class="btn-close text-end" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <h4 class="card-title text-center text-primary">Fecha de Nacimiento</h4>
                                                            <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <label class="form-label" for="parentesco-{{i}}">Parentesco:</label>
                            <div class="d-flex" style="position: relative;">
                                <select class="fs-6 me-2 form-select text-primary custom-select" id="parentesco-{{i}}" formControlName="parentesco" required>
                                    <option value="" disabled selected>--Selecciona una opción--</option>
                                    <option value="hijo">Hijo/a</option>
                                    <option value="conyuge">Cónyuge</option>
                                    <option value="padre">Padre/Madre</option>
                                    <option value="hermano">Hermano/a</option>
                                    <option value="otro">Otro</option>
                                </select>
                                <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalCinco">
                                    <i class="fa-solid fa-circle-info text-primary p-1"></i>
                                </button>
                                <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalCinco" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                        <div class="container">
                                            <div class="row d-flex justify-content-center align-items-center">
                                                <div class="col-10">
                                                    <div class="card">
                                                        <div class="card-body p-5">
                                                            <div class="cont-btn-close">
                                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                                <i class="fa-solid fa-x"></i>
                                                                </button>
                                                            </div>
                                                            <h4 class="card-title text-center text-primary">Parentesco</h4>
                                                            <p class="card-text text-center">Información sobre los pasos que faltan</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <label class="form-label" for="nombres">Porcentaje:</label>
                            <div class="d-flex" style="position: relative;">
                                <input class="form-control mb-0 me-2" type="number" id="apellidos" formControlName="porcentaje" required>
                                <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalSeis">
                                    <i class="fa-solid fa-circle-info text-primary p-1"></i>
                                </button>
                                <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalSeis" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                        <div class="container">
                                            <div class="row d-flex justify-content-center align-items-center">
                                                <div class="col-10">
                                                    <div class="card">
                                                        <div class="card-body p-5">
                                                            <div class="cont-btn-close">
                                                                <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                                    <i class="fa-solid fa-x"></i>
                                                                </button>
                                                                </div>
                                                            <h4 class="card-title text-center text-primary">Porcentaje</h4>
                                                            <p class="card-text text-center">Info adicional</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="beneficiario.get('porcentaje')?.invalid && (beneficiario.get('porcentaje')?.dirty || beneficiario.get('porcentaje')?.touched)" class="alert alert-danger">
                                <div *ngIf="beneficiario.get('porcentaje')?.errors?.['required']">Este campo es obligatorio.</div>
                                <div *ngIf="beneficiario.get('porcentaje')?.errors?.['min'] || beneficiario.get('porcentaje')?.errors?.['max']">El valor debe estar entre 1 y 100.</div>
                            </div>

                            <div *ngIf="i > 0" class="col-12 contenedor-botones-benef">
                                <div class="" style="width: fit-content;">
                                    <button class="mt-2 text-danger btn btn-danger w-100 w-100 d-flex justify-content-start align-items-center" type="button" (click)="removerBeneficiario(i)">
                                        <i class="fs-2 p-1 ps-0 pe-2 fa-solid fa-circle-minus"></i>Eliminar Beneficiario
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div class="col-12 contenedor-botones-benef aparece-tres">
                            <div class="" style="width: fit-content;">
                                <button class="text-secondary btn btn-secondary d-flex justify-content-start align-items-center" type="button" (click)="agregarBeneficiario()">
                                    <i class="fs-2 p-1 ps-0 pe-2 fa-solid fa-circle-plus"></i>Agregar Beneficiario
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="col-12 contenedor-botones aparece-tres">
                    <div class="col-11">
                        <button class="btn btn-primary w-100" type="submit" [disabled]="!validadorSumaPorcentajesSuccess">Continuar</button>
                    </div>
                </div>
                
            </form>
            
            <div
            class="position-fixed bottom-0 start-50 translate-middle-x w-100 text-center text-light bg-primary p-5 aparece-cuatro"
            style="z-index: 9999;"
            [class.bg-primary]="getPorcentajeTotal() <= 100"
            [class.bg-danger]="getPorcentajeTotal() > 100"
            [class.text-secondary]="getPorcentajeTotal() > 100"
            [class.bg-success]="getPorcentajeTotal() === 100">
                <span *ngIf="getPorcentajeTotal() <= 100" class="text-light">Porcentaje Total: {{ getPorcentajeTotal() }}%</span>
                <span *ngIf="getPorcentajeTotal() > 100" class="text-light">El porcentaje total no puede exceder el 100% <strong>(Actual: {{ getPorcentajeTotal() }}%)</strong></span>
            </div>
            
                  
            <div class="w-100 my-5 d-flex flex-column justify-content-center align-items-center aparece-tres">
                <a class="col-12 text-center my-5" routerLink="/perfil-nuevo06" routerLinkActive="active-link">"Avanzar con la prueba"</a>
            </div>
                  
        </div>
    </div>
</div>