import { Component, OnInit } from '@angular/core';
import { InversionesService } from '../../../services/cuentas/inversiones.service';
import { Inversion } from '../../../models/inversion.model';
import { Router } from '@angular/router';
import { TempDataService } from '../../../services/temp-data.service';

@Component({
  selector: 'app-perfil01',
  templateUrl: './perfil01.component.html',
  styles: []
})
export class Perfil01Component implements OnInit {
  title = 'Mis contratos';
  inversionesActivas: Inversion[] = [];
  inversionesInactivas: Inversion[] = [];

  constructor(
    private inversionesService: InversionesService,
    private router: Router,
    private tempDataService: TempDataService
  ) {}

  ngOnInit() {
    this.clearDatosPersonales();
    this.cargarInversiones();
  }

  seleccionarCuenta(nombreCuenta: string) {
    console.log('Guardando nombreCuenta:', nombreCuenta);
    this.tempDataService.setFormDatosPersonales({ nombreCuenta });
    localStorage.setItem('nombreCuenta', nombreCuenta);
    console.log('nombreCuenta guardado:', this.tempDataService.getCurrentDatosPersonales());
    this.router.navigate(['/perfil02']);
  }

  cargarInversiones() {
    this.inversionesService.obtenerInversiones().subscribe({
      next: (data: Inversion[]) => {
        console.log('Datos de inversiones obtenidos:', data);
        this.inversionesActivas = data.filter(inversion => inversion.estatus === 'ACTIVO');
        this.inversionesInactivas = data.filter(inversion => inversion.estatus === 'PENDIENTE');
        console.log('Inversiones activas:', this.inversionesActivas);
        console.log('Inversiones inactivas:', this.inversionesInactivas);
      },
      error: (error) => console.error('Error al obtener inversiones', error)
    });
  }

  private clearDatosPersonales() {
    console.log('Limpiando datos personales');
    this.tempDataService.clearFormDatosPersonales();
    localStorage.removeItem('nombreCuenta');
  }
}