<app-user-status></app-user-status>

<div class="container d-flex justify-content-center align-items-center contenedor-94">
    <div class="d-flex flex-column justify-content-evenly align-items-center">
        <div class="col-11 d-flex flex-column justify-content-center align-items-center">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center aparece-tres">
                <span class="w-100 text-start mb-5 progresoFel">¡Contraseña restablecida exitosamente!</span>
                <h4 class="w-100 pe-3 mt-2 mb-5">Ahora puedes ingresar a tu cuenta iniciando sesión.</h4>
            </div>
            
            <div class="col-11 mt-5 aparece-tres">
                <button class="btn btn-primary w-100" routerLink="/login" routerLinkActive="active-link">Continuar</button>
            </div>
        </div>

    </div>
  </div>

  
  