<app-menu-preregistro></app-menu-preregistro>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">
        <div class="col-11 mb-5 progresoRegistroCaja aparece-dos">
            <div class="d-flex flex-column justify-content-center align-items-center">
                
                <div class="col-11 aparece">
                    <h1>{{ title }}</h1>
                </div>

                <div class="col-11 aparece-dos">
                    <p>Asegúrate que los datos coincidan con los de tu identificación oficial.</p>
                    <div>
                    <div class="review-section">
                        <h4 class="fs-3">Nombre(s):</h4>
                        <p>{{ revisarFormDatos.value.nombres }}</p>
                        <h4 class="fs-3">Apellido(s):</h4>
                        <p>{{ revisarFormDatos.value.apellidos }}</p>
                        <h4 class="fs-3">RFC:</h4>
                        <p>{{ revisarFormDatos.value.rfc }}</p>
                        <h4 class="fs-3">Género:</h4>
                        <p>{{ revisarFormDatos.value.genero }}</p>
                        <h4 class="fs-3">Fecha de Nacimiento:</h4>
                        <p>{{ revisarFormDatos.value.fechanac }}</p>
                        <h4 class="fs-3">Nacionalidad:</h4>
                        <p>{{ revisarFormDatos.value.nacionalidad }}</p>
                        
                    </div>
                </div>

                <div *ngIf="errorMessage" class="alert alert-danger">
                    {{ errorMessage }}
                </div>

                <div class="col-12 contenedor-botones aparece-tres">
                    <div class="col-12">
                        <button class="w-100 mb-3 btn btn-primary" (click)="onSubmit()">Confirmar</button>
                        <button class="w-100 btn btn-secondary" (click)="onEdit()">Corregir Datos</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
