import { Component } from '@angular/core';
import { AuthService } from '../../../services/logged/auth.service';

@Component({
  selector: 'app-comprobante03',
  templateUrl: './comprobante03.component.html',
})
export class Comprobante03Component {
  
  constructor(
    private authService: AuthService,
  ) {}

  logout(): void {
    this.authService.logout();
  }
  
}

/* import { AuthService } from '../../../services/logged/auth.service';
constructor(
  private authService: AuthService,
) {}

logout(): void {
  this.authService.logout();
} */