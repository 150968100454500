<app-user-status></app-user-status>

<div class="container-fluid contenedor-94 d-flex flex-column justify-content-center">
    <div class="row pb-5 d-flex justify-content-evenly align-items-center">
        <div class="col-11 pb-5">
        
            <div class="col-11 aparece">
                <h1>{{ title }}</h1>
            </div>

            <div class="col-11 mt-3 mb-2 aparece-dos">
                <h4 class="mb-2">Por favor verifica la bandeja de tu correo electrónico</h4>
                <p class="mb-2 mt-5">Recuerda revisar tu bandeja de spam</p>
            </div>

            <div class="col-11 contenedor-botones aparece-tres">
                <a class="col-12 text-center my-5" routerLink="/restablecida" routerLinkActive="active-link">"Avanzar con la prueba"</a>
            </div>
            
        </div>
    </div>
</div>
