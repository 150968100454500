<app-user-status></app-user-status>

<div class="container d-flex justify-content-center align-items-center contenedor-94">
        <div class="d-flex flex-column justify-content-evenly align-items-center">
            
            <div class="col-md-5 col-8 pb-5 aparece">
                <img class="img-fluid" src="../../../assets/logoAC_completo_color.png">
                <h5 class="text-center">Fondo de inversión privado</h5>
            </div>
        
            <div class="col-md-8 col-11 py-5 my-5 aparece-dos">
                <h3 class="text-md-center"><span>Invierte</span> en Estados Unidos de forma <span>segura y sencilla.</span></h3>
                <p class="text-md-center">Multiplica y protege tu capital</p>
            </div>

            <div class="col-12 contenedor-botones aparece-tres">
                <div class="col-11">
                    <button class="btn btn-primary w-100" routerLink="/preregistro02" routerLinkActive="active-link">Crear Cuenta</button>
                    <button class="btn btn-secondary w-100 my-2" routerLink="/login" routerLinkActive="active-link">Iniciar sesión</button>
                    <button class="btn btn-light w-100" routerLink="/asistencia" routerLinkActive="active-link">Asistencia <i class="ps-3  fs-2 fa-solid fa-headset overflow-hidden"></i></button>
                </div>
            </div>

        </div>
</div>

