<div class="container-fluid d-flex justify-content-center align-items-center contenedor contenedor-bg">
    <div class="row d-flex flex-column justify-content-center align-items-center">

        
        <img src="../../../assets/logoAC_completo_blanco.png" class="col-8 aparece">

        <div class="w-auto aparece">
            <span class="text-center text-warning fs-4">Asegurando tu tranquilidad financiera</span>
            <div class="linea mb-5"></div>
        </div>

        <button class="col-8 btn btn-outline-light mt-5 aparece-tres" (click)="navegar()">Comenzar</button>
        
    </div>
</div>


  