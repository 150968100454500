import { HttpClient } from '@angular/common/http'; 
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { TempDataService } from '../../../services/temp-data.service';

@Component({
  selector: 'app-registro01',
  templateUrl: './registro01.component.html',
  styleUrls: []
})
export class Registro01Component {

  title = "Creemos tu cuenta";
  formDatos: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';

  constructor( 
    private http: HttpClient, 
    private _location: Location, 
    private router: Router, 
    private cdr: ChangeDetectorRef, 
    private tempDataService: TempDataService,
  ) {
    this.formDatos = new FormGroup({
      nombres: new FormControl('', { validators: [Validators.required, Validators.pattern(/^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ\s]+$/)]}),
      apellidos: new FormControl('', { validators: [Validators.required, Validators.pattern(/^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ\s]+$/)]}),
      genero: new FormControl('', { validators: [Validators.required] }),
      fechanac: new FormControl('', { validators: [Validators.required, this.mayorDeEdadValidator()] }),
      nacionalidad: new FormControl('', [Validators.required]),
      rfc: new FormControl('', [
        Validators.required,
        Validators.minLength(13),
        Validators.maxLength(13),
        Validators.pattern(/^[A-Z0-9]{13}$/)
      ])
    });
  }

  get nombres(){
    return this.formDatos.get('nombres');
  }
  get apellidos(){
    return this.formDatos.get('apellidos');
  }
  get genero(){
    return this.formDatos.get('genero');
  }
  get fechanac(){
    return this.formDatos.get('fechanac');
  }
  get nacionalidad(){
    return this.formDatos.get('nacionalidad');
  }
  get rfc(){
    return this.formDatos.get('rfc');
  }

  mayorDeEdadValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valor = control.value;
      const hoy = new Date();
      const fechaLimite = new Date(hoy.getFullYear() - 18, hoy.getMonth(), hoy.getDate());
      return new Date(valor) <= fechaLimite ? null : { 'menorDeEdad': true };
    };
  }

  onSubmit() {
    if (this.formDatos.valid) {
      this.tempDataService.setFormDatosPersonales(this.formDatos.value);
      this.router.navigate(['/registro02']);
    }
  }

}
