import { Component } from '@angular/core';

@Component({
  selector: 'app-perfil-retiro02',
  templateUrl: './perfil-retiro02.component.html',
  styles: [
  ]
})
export class PerfilRetiro02Component {

}
