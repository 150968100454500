import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-restablecer-verifica',
  templateUrl: './restablecer-verifica.component.html',
  styleUrls: ['./restablecer-verifica.component.css']
})
export class RestablecerVerificaComponent {

  constructor (private router: Router, private _location: Location){ }

  title = "Restablece tu contraseña";

}
