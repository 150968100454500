import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/logged/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
    ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.getToken();
    const headersConfig: Record<string, string> = {};

    if (authToken) {
      headersConfig['Authorization'] = authToken;
    }

    if (!(request.body instanceof FormData)) {
      headersConfig['Content-Type'] = 'application/json';
    }

    if (Object.keys(headersConfig).length > 0) {
      request = request.clone({ setHeaders: headersConfig });
    }

    return next.handle(request);
  }
}