<app-user-status [showBackButton]="true"></app-user-status>

<app-banner-cuenta></app-banner-cuenta>

<app-boton-agregar></app-boton-agregar>
    
<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">
        <div class="col-11 mb-3 aparece">
            <h1>{{ title }}</h1>
        </div>

        <div class="col-11 mb-5 aparece-dos">
            <form [formGroup]="formDatos" class="d-flex flex-column justify-content-center align-items-center" id="formDatos">
                <div class="col-12 aparece-dos">
                    <p style="transform: translate(0) !important;">Recuerda que si tu solicitud de retiro no cumple con los requisitos, será rechazada automáticamente.</p>
                    <ul class="text-primary my-5">
                        <li class="fs-6">Debe haber transcurrido al menos un año de la fecha de inicio de tu inversión</li>
                        <li class="fs-6">Solo podrás solicitar tu retiro sobre la cantidad de los rendimientos generados</li>
                    </ul>
                    <div>
                        <label class="form-label" for="montoRetiro">Retirar:</label>
                        <div class="d-flex" style="position: relative;">
                            <input class="form-control mb-0 me-2" type="text" id="montoRetiro" formControlName="montoRetiro" required>
                            <button type="button" class="fs-4 bg-transparent border-0 d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalDos">
                                <i class="fa-solid fa-circle-info text-primary p-1"></i>
                            </button>
                            <div class="modal fade aparece-modal" style="z-index: 9999999999999;" id="modalDos" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-fullscreen-sm-down d-flex justify-content-center align-items-center">
                                    <div class="container">
                                        <div class="row d-flex justify-content-center align-items-center">
                                            <div class="col-10">
                                                <div class="card">
                                                    <div class="card-body p-5">
                                                        <div class="cont-btn-close">
                                                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                                              <i class="fa-solid fa-x"></i>
                                                            </button>
                                                        </div>
                                                        <h4 class="card-title text-center text-primary">Nombres</h4>
                                                        <p class="card-text text-center">Info adicional</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="formDatos.get('montoRetiro')?.invalid && (formDatos.get('montoRetiro')?.dirty || formDatos.get('montoRetiro')?.touched)" class="alert alert-danger">
                            <span *ngIf="formDatos.get('montoRetiro')?.errors?.['required']">Este campo no puede quedar vacío</span>
                            <span *ngIf="formDatos.get('montoRetiro')?.errors?.['pattern']">Ingrese un monto válido</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="errorMessage" class="w-100 mt-5 alert alert-danger">
                    {{ errorMessage }}
                </div>
                <div *ngIf="successMessage" class="w-100 mt-5 alert alert-success">
                    {{ successMessage }}
                </div>

                <div class="col-12 contenedor-botones aparece-tres">
                    <div class="col-12">
                        <button type="button" class="btn btn-primary" (click)="retirarRendimientos()" [disabled]="isLoading">Retirar</button>
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</div>