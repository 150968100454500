import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/logged/auth.service';

@Component({
  selector: 'app-registro09',
  templateUrl: './registro09.component.html',
  styleUrls: []
})
export class Registro09Component implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  logout(): void {
    this.authService.logout();
  }
}