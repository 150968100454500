import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProgresoUsuarioService } from '../../../services/progreso-usuario/progreso-usuario.service';

@Component({
  selector: 'app-comprobante01',
  templateUrl: './comprobante01.component.html',
  styleUrls: []
})
export class Comprobante01Component implements OnInit {

  title = 'Activa tu cuenta';
  items = [{ textoCopiar: 'Texto abajo' }, { textoCopiar: 'Texto abajo 2' }, { textoCopiar: 'Texto abajo 3' }];
  errorMessage: string = '';
  successMessage: string = '';

  constructor(
    private router: Router,
    private progresoUsuarioService: ProgresoUsuarioService
  ) {}

  ngOnInit(): void {
    this.progresoUsuarioService.checkUserProgressAndNavigate();
  }

  copiarAlPortapapeles(elemento: HTMLParagraphElement) {
    navigator.clipboard.writeText(elemento.innerText)
      .then(() => {
        this.successMessage = 'Dato copiado en el portapapeles.';
        this.errorMessage = '';
        setTimeout(() => {
          this.successMessage = '';
        }, 3000);
      })
      .catch(err => {
        this.errorMessage = 'Error al copiar texto: ' + err;
        this.successMessage = '';
        setTimeout(() => {
          this.errorMessage = '';
        }, 3000);
      });
  }
}