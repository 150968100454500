<app-user-status></app-user-status>


<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">
        
        <div class="col-11">
            <h1 class="aparece">{{ title }}</h1>

                <div class="aparece-dos mt-5 pt-5">
                    <h4 class="mb-2">Por favor verifica tu correo electrónico</h4>
                    <p class="mb-2 mt-5">Recuerda revisar tu bandeja de spam</p>
                </div>

                <div class="col-12 contenedor-botones aparece-tres">
                    <div class="col-11">
                        <button class="btn btn-primary w-100" routerLink="/preregistro05" routerLinkActive="active-link">Continuar</button>
                    </div>
                </div>
        </div>
    </div>
</div>

