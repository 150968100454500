import { Component } from '@angular/core';

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html',
  styles: [
  ]
})
export class TerminosComponent {

  title = 'Arcsa Capital, LLC y Arcsa Services Protection, LLC website terms and conditions of use'

}
