<app-user-status [showBackButton]="true"></app-user-status>

<div class="container-fluid d-flex justify-content-center align-items-between contenedor-94">
    <div class="row d-flex flex-column justify-content-center align-items-center">
        
            <div class="col-11 mb-3 aparece-dos">
                <h2 class="mb-4">Por favor toma en cuenta lo siguiente para poder crear tu cuenta:</h2>
                <ul>
                    <li>Tener una buena conexión a internet</li>
                    <li>Ser mayor de edad</li>
                    <li>Verifica que tus datos coincidan con los de tu identificación oficial</li>
                    <li>Corrobora la información que ingreses para evitar errores en tu registro</li>
                </ul>
            </div>

            <div class="col-11 mt-3 aparece-tres">
                <h2 class="mb-4">Recuerda tener a la mano:</h2>
                <ul>
                    <li>Tu identificación oficial <strong>VIGENTE</strong></li>
                    <li>Tu información bancaria</li>
                    <li>Datos básicos de tus beneficiarios</li>
                </ul>
            </div>

        <div class="col-11 contenedor-botones aparece-cuatro">
            <button class="btn btn-primary w-100" routerLink="/preregistro03" routerLinkActive="active-link">Comenzar</button>
            <a class="d-flex justify-content-center mt-3 link-underline link-underline-opacity-0" routerLink="/login" routerLinkActive="active-link">ó Inicia Sesión</a>
        </div>
    </div>


    
</div>
